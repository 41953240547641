/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ConfigProvider, Select, TableColumnsType } from 'antd'
import { useState } from 'react'
import DataTable from 'src/components/Table/DataTable'
import Button from 'src/components/ui/Button'

export default function BattleCasino() {
  const [query, setQuery] = useState({ limit: 10, page: 1, total: 0 })

  const BattleColumns: TableColumnsType<any> = [
    {
      title: '배팅시간',
      key: 'field1',
      width: '20%',
      align: 'center',
      dataIndex: 'field1'
    },
    { title: '게임', key: 'field2', width: '50%', align: 'center', dataIndex: 'field2' },
    {
      title: '당첨금액',
      key: 'field4',
      width: '10%',
      align: 'center',
      dataIndex: 'field4',
      render: (record) => <span className={`${record.includes('-') ? 'text-red-1' : 'text-white'}`}>{record} 원</span>
    }
  ]

  const data = [
    {
      field1: '2024-04-19 17:42',
      field2: 'CQ9',
      field3: '250 원',
      field4: '50 원',
      field5: '당첨'
    },
    {
      field1: '2024-04-19 17:42',
      field2: 'CQ9',
      field3: '250 원',
      field4: '50 원',
      field5: '당첨'
    },
    {
      field1: '2024-04-19 17:42',
      field2: 'CQ9',
      field3: '250 원',
      field4: '50 원',
      field5: '당첨'
    }
  ]
  return (
    <div className='battle__type mt-5 md:mt-0'>
      <div className='flex items-center justify-between gap-2 flex-wrap'>
        <Button className='pt-2 pb-2 pl-5 pr-5 bg-primary rounded-md text-[#333] font-medium'>카지노</Button>
        <Select
          showSearch
          optionFilterProp='children'
          defaultValue='모든 게임사'
          className='w-[180px] h-[40px]'
        ></Select>
      </div>
      <div className='battle__type'>
        <ConfigProvider
          renderEmpty={() => (
            <span className='text-center font-medium text-[#333]'>해당 게임에 배팅내역이 존재하지 않습니다.</span>
          )}
        >
          <DataTable
            columns={BattleColumns}
            expandable={{
              expandedRowRender: (record: any) => <div dangerouslySetInnerHTML={{ __html: record.content }}></div>,
              expandRowByClick: true,
              expandIconColumnIndex: -1
            }}
            pagination={{
              position: ['bottomCenter'],
              total: query.total,
              pageSize: query.limit
            }}
            dataSource={data}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}
