/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery } from '@apollo/client'
import { Modal } from 'antd'
import cn from 'classnames'
import { useState } from 'react'
import { toast } from 'react-toastify'
import Loading from 'src/components/Loading'
import Button from 'src/components/ui/Button'
import { CANCEL_BET_HISTORY_MUTATION } from 'src/libs/apis/graphql/mutations/sport.mutation'
import { GET_BATTLE_HISTORY_SPORTS } from 'src/libs/apis/graphql/queries/sport.query'
import { formatToTwoDecimalPlaces } from 'src/libs/utils/utilFuncs'
import { SportsBattleType } from 'src/types/sports.type'
interface IBattleBetGames {
  awayTeamName: string
  gameType: string
  homeTeamName: string
  marketName: string
  selPickName: string
  selectIdx: string
  selectRate: number
  startDate: string
  status: number
}

const columns = [
  { title: '경기시간', fieldName: 'date', width: '500' },
  { title: '리그명', fieldName: 'league', width: '500' },
  { title: '홈팀vs원정팀', fieldName: 'team', width: '500' },
  { title: '경기타임', fieldName: 'time', width: '500' },
  { title: '배팅타입', fieldName: 'type', width: '500' },
  { title: '배당', fieldName: 'ratio', width: '500' },
  { title: '경기결과', fieldName: 'result', width: '500' },
  { title: '배팅결과', fieldName: 'status', width: '500' }
]

const GAME_TYPES = {
  cross: '2',
  live: '3'
}

export default function Sports() {
  const [query, setQuery] = useState({ limit: 5, page: 1, total: 0, offset: 1, game_type: GAME_TYPES.cross })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [cancelId, setCancelId] = useState('')
  const [cancelBetMutation] = useMutation(CANCEL_BET_HISTORY_MUTATION)

  const {
    data: sportsData,
    loading: loadingSports,
    refetch: refetchSportsBattle
  } = useQuery<SportsBattleType>(GET_BATTLE_HISTORY_SPORTS, {
    variables: { game_type: query.game_type ?? '', limit: query.limit ?? 10, offset: query.offset ?? 0 },
    context: { apiName: 'sports' },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  })
  const findClosestStartDate = (dataArray: any | undefined) => {
    return dataArray.reduce(
      (shortest: any, current: any) => {
        const currentDate = new Date(current.startDate)
        const shortestDate = shortest ? new Date(shortest.startDate) : undefined
        if (!shortestDate || currentDate.getTime() < shortestDate.getTime()) {
          return current
        }

        return shortest
      },
      undefined as any | undefined
    )
  }

  const checkTimeOutAdminBet = (timeLeftDisable: any, timeLeftCancelable: any) => {
    if (timeLeftDisable > 0 || timeLeftCancelable === 1) {
      return true
    } else {
      return false
    }
  }

  // const checkCaseBattle = (day1: any | undefined, dataArray: any | undefined) => {
  //   const day2 = findClosestStartDate(dataArray)?.startDate
  //   const timeToCheck1 = new Date(day1)
  //   const timeToCheck2 = new Date(day2)
  //   const now = new Date(new Date().toLocaleString('en-US', { timeZone: 'Asia/Seoul' }))

  //   const difference1 = (now.getTime() - timeToCheck1.getTime()) / (1000 * 60)
  //   const difference2 = (timeToCheck2.getTime() - now.getTime()) / (1000 * 60)
  //   const result1 = difference1 > BETAFTER
  //   const result2 = difference2 > BETBEFORE

  //   return result1 && result2
  // }

  const showModal = (id: string) => {
    setIsModalOpen(true)
    setCancelId(id)
  }

  const handleOk = () => {
    cancelBetMutation({
      context: { apiName: 'sports' },
      variables: {
        cart_idx: cancelId
      },
      onCompleted: (data) => {
        toast.success(data?.CancelBetMutation?.message)
        refetchSportsBattle()
      }
    })
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const itemsPerPage = query.limit
  const sportsDataBet = (!loadingSports && sportsData?.BetHistoryQuery?.data) || []
  const total = (!loadingSports && sportsData?.BetHistoryQuery?.total) || 0
  const totalPages = Math.ceil(total / itemsPerPage)

  const handlePageChange = (newPage: any | undefined) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setQuery({ ...query, offset: newPage })
      setCurrentPage(newPage)
    }
  }

  const renderStatus = (value: number | undefined, className?: string) => {
    switch (value) {
      case 0:
        return (
          <button
            disabled
            className={cn(
              'w-max bg-[#000] text-12 text-primary-7 border-[#e06666] border rounded-[5px] py-[3px] px-[1rem] self-center justify-center place-self-center min-w-24 md:min-w-24',
              className
            )}
            onClick={() => {}}
          >
            대기중
          </button>
        )
      case 1:
        return (
          <button
            disabled
            className={cn(
              'w-max bg-[#ff0000] text-12 rounded-[5px] py-[3px] px-[1rem] self-center justify-center place-self-center min-w-24 md:min-w-24',
              className
            )}
            onClick={() => {}}
          >
            낙첨
          </button>
        )
      case 2:
        return (
          <button
            disabled
            className={cn(
              'w-max bg-[#4986e8] text-12 rounded-[5px] py-[3px] px-[1rem] self-center justify-center place-self-center min-w-24 md:min-w-24',
              className
            )}
          >
            당첨
          </button>
        )
      case 3:
        return (
          <button
            disabled
            className='w-max bg-[#F79F2F] rounded-[5px] py-[3px] px-[1rem] self-center justify-center place-self-center min-w-24 md:min-w-24'
          >
            적특
          </button>
        )
      case 5:
        return (
          <button
            disabled
            className={cn(
              'w-max bg-[#be311e] rounded-[5px] py-[3px] px-[1rem] self-center justify-center place-self-center min-w-24 md:min-w-24',
              className
            )}
          >
            취소
          </button>
        )
      default:
        return <></>
    }
  }

  const renderSettleStatus = (value: number | undefined, className?: string) => {
    switch (value) {
      case 0:
        return (
          <button
            disabled
            className={cn(
              'w-max bg-[#000] text-12 text-primary-7 border-[#e06666] border rounded-[5px] py-[3px] px-[1rem] self-center justify-center place-self-center min-w-24 md:min-w-24',
              className
            )}
            onClick={() => {}}
          >
            대기중
          </button>
        )
      case 1:
        return (
          <button
            disabled
            className={cn(
              'w-max bg-[#ff0000] text-12 rounded-[5px] py-[3px] px-[1rem] self-center justify-center place-self-center min-w-24 md:min-w-24',
              className
            )}
            onClick={() => {}}
          >
            낙첨
          </button>
        )
      case 2:
        return (
          <button
            disabled
            className={cn(
              'w-max bg-[#4986e8] text-12 rounded-[5px] py-[3px] px-[1rem] self-center justify-center place-self-center min-w-24 md:min-w-24',
              className
            )}
          >
            당첨
          </button>
        )
      case 3:
        return (
          <button
            disabled
            className='w-max bg-[#be311e] rounded-[5px] py-[3px] px-[1rem] self-center justify-center place-self-center min-w-24 md:min-w-24'
          >
            취소
          </button>
        )
      case 5:
        return (
          <button
            disabled
            className={cn(
              'w-max bg-[#be311e] rounded-[5px] py-[3px] px-[1rem] self-center justify-center place-self-center min-w-24 md:min-w-24',
              className
            )}
          >
            취소
          </button>
        )
      default:
        return <></>
    }
  }

  const renderResults = (value: number | undefined) => {
    switch (value) {
      case 0:
        return (
          <button disabled className='bg-[#773f05] text-12 rounded-[5px] px-4 py-1 min-w-[100px] md:min-w-24 max-h-8'>
            정산대기중
          </button>
        )
      default:
        return (
          <button disabled className='bg-[#4986e8] text-12 rounded-[5px] px-4 py-1 min-w-[100px] md:min-w-24 max-h-8'>
            정산완료
          </button>
        )
    }
  }

  const modalExampleCancel = () => {
    return (
      <Modal
        title=''
        onCancel={handleCancel}
        open={isModalOpen}
        centered
        footer={null}
        closeIcon={false}
        rootClassName='sports-modal'
      >
        <div className='flex flex-col justify-between gap-4 p-6 relative items-center bg-black-1 border-black-2 border text-white rounded'>
          <span className='text-20 font-medium'>배팅을 취소합니다.</span>
          <div className='self-stretch flex items-center justify-center gap-4'>
            <button
              className='bg-black-4 text-white px-10 py-2 rounded hover:bg-black-2 duration-200'
              onClick={handleCancel}
            >
              취소
            </button>
            <button
              className='bg-primary text-white px-10 py-2 rounded hover:bg-primary-2 duration-200'
              onClick={handleOk}
            >
              확인
            </button>
          </div>
          <button className='absolute top-4 right-4' onClick={handleCancel}>
            <img src='/Close.png' alt='close' className='size-4' />
          </button>
        </div>
      </Modal>
    )
  }

  const handleFilter = (type: string) => {
    setQuery({ ...query, game_type: type })
  }
  return (
    <div className='battle__type mt-5 xs:mt-0'>
      {modalExampleCancel()}
      <div className='flex items-center justify-between gap-2 flex-wrap'>
        <div className='flex items-center gap-2'>
          <Button
            className={`transition-all h-10 rounded bg-secondary-2 text-primary hover:bg-primary hover:text-secondary-2 w-fit px-4 min-w-20 font-medium text-14 ${query?.game_type === GAME_TYPES.cross ? '!bg-primary !text-content' : ''}`}
            onClick={() => handleFilter(GAME_TYPES.cross)}
            disabled={loadingSports}
          >
            스포츠
          </Button>
          <Button
            className={`transition-all h-10 rounded bg-secondary-2 text-primary hover:bg-primary hover:text-secondary-2 w-fit px-4 min-w-20 font-medium text-14 ${query?.game_type === GAME_TYPES.live ? '!bg-primary !text-content' : ''}`}
            onClick={() => handleFilter(GAME_TYPES.live)}
            disabled={loadingSports}
          >
            인플레이
          </Button>
        </div>
      </div>
      <div className='battle__type'>
        {/* DataTable Bet battle EX 2*/}

        <div className='block overflow-x-auto border-t-2 border-primary'>
          <div className='grid grid-cols-8 bg-[#101216] min-w-[800px] px-2 py-4'>
            {columns.map((item, index) => {
              return (
                <h3
                  className={`flex-shrink-1 whitespace-nowrap w-full text-[14px] ${index === 0 ? 'text-left' : 'text-center'} ${index === 7 ? 'text-right pr-[22px]' : ''}`}
                  key={index}
                >
                  {item.title}
                </h3>
              )
            })}
          </div>

          {loadingSports ? (
            <Loading />
          ) : sportsDataBet.length > 0 ? (
            sportsDataBet?.map((item, index) => (
              <div
                key={index}
                className={`mt-4 py-2 px-2 min-w-[800px] mb-[30px] rounded-[5px] ${index % 2 == 1 ? 'bg-primary-8' : 'bg-primary-9'}`}
              >
                {item?.betList?.map((dataBetList, idx, array) => (
                  <div
                    className={`grid grid-cols-8 py-2 text-[13px] ${idx === array.length - 1 ? '' : 'line-battle'}`}
                    key={idx}
                  >
                    <h3 className='w-full text-left self-center justify-center place-self-center'>
                      {dataBetList.startDate}
                    </h3>
                    <h3 className='w-full text-center self-center justify-center place-self-center'>
                      {dataBetList.leagueName}
                    </h3>
                    <h3 className='w-full text-center self-center justify-center place-self-center'>
                      {dataBetList.homeTeamName} <span className='text-primary'>vs</span> {dataBetList.awayTeamName}
                    </h3>
                    <h3 className='w-full text-center self-center justify-center place-self-center'>
                      {dataBetList.marketName}
                    </h3>
                    <h3 className='w-full text-center self-center justify-center place-self-center'>
                      {`${dataBetList?.labelBetStatus}`}
                    </h3>
                    <h3 className='w-full text-center self-center justify-center place-self-center'>
                      {formatToTwoDecimalPlaces(dataBetList.selectRate || 0)}
                    </h3>
                    <h3 className='w-full text-center self-center justify-center place-self-center'>
                      {item.settlementStatus !== 0 ? `${dataBetList.homeScore} - ${dataBetList.awayScore}` : '-'}
                    </h3>
                    <h3 className='w-full text-right pr-[22px] self-center justify-center place-self-center'>
                      {renderStatus(dataBetList.status)}
                    </h3>
                  </div>
                ))}
                <div className='mt-4'>
                  <p className='flex w-full text-[13px] gap-[16px]'>
                    <div className='w-[22.3%] self-center place-self-center break-words'>배팅접수시간: {item.date}</div>
                    <span className='flex justify-end w-full'>
                      {item.bonusFolder > 0 && (
                        <span className='mr-2 self-center justify-center place-self-center'>
                          {item.bonusFolder} 폴더 보너스 :{' '}
                          <span
                            style={{
                              textDecoration: item.isCancelledBonusFolder ? 'line-through' : 'none',
                              color: item.isCancelledBonusFolder ? 'grey' : 'inherit'
                            }}
                          >
                            {' '}
                            보너스배당률({item.bonusFolderRate})
                          </span>
                        </span>
                      )}

                      <span className='self-center justify-center place-self-center mr-2'>
                        예상당첨금액 : (배팅금 <span className='text-green-1'>{item.betAmount?.toLocaleString()}</span>{' '}
                        X 배당률 <span className='text-green-1'>{item.rate?.toFixed(2)}</span>) =
                        <span className='text-primary-6'> {item.estimateAmount?.toLocaleString()}</span> 원 / 당첨금액 :{' '}
                        <span className='text-green-1'>{item.winAmount?.toLocaleString()} </span>원
                      </span>
                      {/* {item.settlementStatus === 0 ? (
                        <button
                          disabled
                          className='bg-[#4986e8] rounded-[5px] px-4 py-1 mr-5 md:mr-10 min-w-32 max-h-8'
                        >
                          정산완료
                        </button>
                      ) : (
                        <button
                          disabled
                          className='bg-[#773f05] rounded-[5px] px-4 mr-5 py-1 md:mr-10 min-w-32  max-h-8'
                        >
                          정산대기중
                        </button>
                      )} */}
                      <div className='mr-[22px]'>{renderResults(item.settlementStatus)}</div>
                      {item.settlementStatus !== 0 ? (
                        <div className='md:mr-[22px] min-w-24 md:min-w-24 max-h-8'>
                          {renderSettleStatus(item.settlementStatus, '!h-full')}
                        </div>
                      ) : item.enableAutoCancel ? (
                        !item.isCancelable ? (
                          <button
                            className='bg-[#6aa74f] order-last rounded-[5px] md:mr-[22px] min-w-24 md:min-w-24 py-[3px] max-h-8'
                            onClick={() => showModal(item._id)}
                          >
                            배팅취소요청
                          </button>
                        ) : checkTimeOutAdminBet(item.timeLeftDisable, item.timeLeftCancelable) ? (
                          <button
                            className='bg-[#6aa74f] order-last rounded-[5px] md:mr-[22px] min-w-24 md:min-w-24 py-[3px] max-h-8'
                            onClick={() => showModal(item._id)}
                          >
                            배팅취소요청
                          </button>
                        ) : (
                          <button
                            disabled
                            className='bg-[#999999] text-12 order-last rounded-[5px] md:mr-[22px] min-w-24 md:min-w-24 py-[3px] max-h-8'
                          >
                            배팅취소요청
                          </button>
                        )
                      ) : (
                        <button
                          disabled
                          className='bg-[#999999] text-12 order-last rounded-[5px] md:mr-[22px] min-w-24 md:min-w-24 py-[3px] max-h-8'
                        >
                          배팅취소요청
                        </button>
                      )}
                    </span>
                  </p>
                </div>
              </div>
            ))
          ) : (
            <div className='bg-[#25292c] p-5 flex justify-center place-items-center'>
              <div className='text-center'>
                <p className='text-14'>해당 게임에 배팅내역이 존재하지 않습니다.</p>
              </div>
            </div>
          )}
        </div>

        {/* Pagination Controls */}
        {!loadingSports && sportsDataBet.length > 0 && (
          <div className='mt-2 mb-4 flex justify-center'>
            <button
              className={`px-4 py-2 bg-primary-10  text-white rounded ${currentPage === 1 ? 'opacity-15' : 'opacity-1'}`}
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <img src='/arrow-left.svg' alt='' />
            </button>

            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                className={`w-[32px] font-dotum font-[600] text-[14px] h-[32px] rounded mx-2 ${currentPage === index + 1 ? 'bg-primary text-content' : 'bg-primary-10 text-white hover:text-primary'}`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            ))}
            <button
              className={`px-4 py-2 bg-primary-10 text-white rounded ${currentPage === totalPages ? 'opacity-15' : 'opacity-1'}`}
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <img src='/arrow-right.svg' alt='' />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
