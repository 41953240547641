import { useQuery } from '@apollo/client'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { pathMiniGameType } from 'src/constants/path'
import { GET_BO_SCORE_POWER_GAME } from 'src/libs/apis/graphql/queries/mini_game.query'
import { setRefetchHistoryBetting } from 'src/libs/stores/minigame.reducer'
import { GameReq, PowerBallBoscoQuery } from 'src/types/minigame.type'

export const useBoscorePowerBallGame = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { data, loading, refetch } = useQuery<PowerBallBoscoQuery, GameReq>(GET_BO_SCORE_POWER_GAME, {
    context: {
      apiName: 'mini-game'
    },
    variables: {
      game: pathMiniGameType[location.pathname]
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      console.log('now', data.PowerBallBoscoQuery.now)
      console.log('end', data.PowerBallBoscoQuery.end_time)
      console.log('time game: ', data.PowerBallBoscoQuery.end_time - data.PowerBallBoscoQuery.now)
      console.log('-----', location.pathname)
      if (!data.PowerBallBoscoQuery.round_id) {
        toast.error('Game is not available')
      }
      dispatch(setRefetchHistoryBetting(false))
    }
  })

  const handleRefetchGame = async () => {
    await refetch()
  }
  useEffect(() => {
    handleRefetchGame()
  }, [location.pathname])

  return {
    data,
    loading,
    refetch,
    handleRefetchGame
  }
}
