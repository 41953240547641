import { ApolloQueryResult, OperationVariables } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { withDrawSchema } from 'src/libs/utils/rules'
import { RootState } from 'src/libs/utils/store'
import { formatCurrency, reFormatCurrency } from 'src/libs/utils/utilFuncs'
import { CASINO_SLOT_WALLET, DataWithDrawCheckType, SPORT_WALLET } from 'src/pages/WithDraw/WithDraw'
import { usePusher } from 'src/providers/PusherProvider'
import { InfoQueryType, SiteInfoType } from 'src/types/common.type'
import Input from '../Input'
import Button from '../ui/Button'

type WithDrawForm = {
  amount: string
}

type WithDrawFormProps = {
  dataInfo: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  withdraw: (data: any) => Promise<any>
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<unknown>>
  moneyUser: {
    value: string
    render: boolean
  }
  setMoneyUser: Dispatch<
    SetStateAction<{
      value: string
      render: boolean
    }>
  >
  mMoney?: string
  mSportsMoney?: string
  wallet: string
  dataWithdrawCheckQuery?: DataWithDrawCheckType
  isLoadingSubmit: boolean
}

const MONEY_INFO_CHANNEL = 'money-info-channel'
const MONEY_INFO_EVENT = 'money-info-event'

export const getValidValue = (value: string | number | undefined) => {
  if (!value) return

  return Math.floor(Number(value) / 10_000) * 10_000
}

type DataWalletKey = {
  key?: string
}
export default function WithDrawForm({
  dataInfo,
  withdraw,
  refetch,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  moneyUser,
  mMoney,
  wallet,
  mSportsMoney,
  dataWithdrawCheckQuery,
  isLoadingSubmit
}: WithDrawFormProps) {
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<WithDrawForm>({
    mode: 'onBlur',
    resolver: yupResolver(withDrawSchema(dataInfo))
  })
  const pusher = usePusher()
  const user = useSelector((state: RootState) => state.auth.user)

  const [disable, setDisable] = useState(false)

  const refreshInput = () => {
    setError('amount', {})
    setValue('amount', '')
  }
  const onSubmit = handleSubmit(async (formData: WithDrawForm) => {
    try {
      await withdraw({
        variables: {
          amount: reFormatCurrency(formData.amount),
          wallet
        },
        context: { apiName: 'money-info' }
      })
    } catch (error) {
      refreshInput()
      return
    }

    refreshInput()
    refetch()
  })

  const dataButton = [
    {
      name: '1만원',
      value: 10000
    },
    {
      name: '3만원',
      value: 30000
    },
    {
      name: '10만원',
      value: 100000
    },
    {
      name: '50만원',
      value: 500000
    },
    {
      name: '100만원',
      value: 1000000
    }
  ]

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const numericValue = value?.replace(/\D/g, '')
    event.target.value = formatCurrency(numericValue)
  }

  const handleButtonClick = (value: number) => {
    const currentValue = watch('amount')
    const newValue = parseInt(currentValue?.replace(/[^\d]/g, '')) || 0
    setValue('amount', formatCurrency((newValue + value).toString()))
  }

  useEffect(() => {
    if (!pusher) return
    const channel = pusher.subscribe(`${MONEY_INFO_CHANNEL}.${user?.mNo}`)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    channel.bind(MONEY_INFO_EVENT, function (data: any) {
      if (data?.moneyInfo?.data?.miType === 'UW') refetch()

      if (data?.moneyInfo?.data?.miType === 'UD') refetch()
    })

    return () => {
      channel.unbind(`${MONEY_INFO_CHANNEL}.${user?.mNo}`)
      pusher.unsubscribe(MONEY_INFO_EVENT)
    }
  }, [refetch, pusher, user?.mNo])

  useEffect(() => {
    setDisable(!dataWithdrawCheckQuery?.WithdrawCheckQuery?.is_enable_sports_casino_withdraw)
  }, [dataWithdrawCheckQuery?.WithdrawCheckQuery?.is_enable_sports_casino_withdraw])

  //If there is a better way, please help me handle it.
  const findWalletByKey = (key: DataWalletKey) =>
    dataWithdrawCheckQuery?.WithdrawCheckQuery?.wallet.find((wallet: string) => wallet.key === key)

  const renderBlock = (key) => {
    const wallet = findWalletByKey(key)
    return (
      !wallet?.is_enable_withdraw && (
        <div className='absolute w-full h-full z-[1] bg-[rgba(0,0,0,0.6)] flex justify-center items-center flex-col gap-2 rounded-md'>
          <img src='/assets/images/recharge/block-icon.png' alt='block' title='block' width='50' height='50' />
          <p className='text-white'>롤링 완료 후 출금가능합니다.</p>
        </div>
      )
    )
  }

  return (
    <form noValidate onSubmit={onSubmit} className='relative rounded-md'>
      {renderBlock(wallet)}
      <div className='p-4 bg-[#101216] rounded-md'>
        <p className='text-white text-[14px] font-bold'>출금신청 금액</p>
        <Input
          name='amount'
          type='text'
          register={register}
          placeholder='출금(환전) 금액'
          errorMessage={errors?.amount?.message}
          classNameError='input-error text-red-1 mt-1 text-12'
          required
          disabled={dataInfo?.InfoQuery?.withdraw_config?.wc_manual_withdraw}
          classNameInput='placeholder:!text-primary text-primary text-14 h-10 p-3 rounded border border-none bg-white'
          className='w-full mt-5'
          onChange={(e) => onInputChange(e)}
        />
        <div className='grid grid-cols-3 gap-4 mt-4'>
          {dataButton?.map((item, index) => (
            <Button
              className='transition-all h-8 w-full rounded bg-secondary-6 text-black hover:bg-[#ccc] hover:text-secondary-2 px-4 min-w-20 font-medium text-14'
              type='button'
              key={index}
              onClick={() => handleButtonClick(item.value)}
            >
              {item?.name}
            </Button>
          ))}
          <Button
            className='transition-all h-8 w-full rounded bg-secondary-6 text-black hover:bg-[#ccc] hover:text-secondary-2 px-4 min-w-20 font-medium text-14'
            type='button'
            onClick={() =>
              setValue(
                'amount',
                formatCurrency(
                  (wallet === 'sports' ? getValidValue(mSportsMoney) : getValidValue(mMoney))?.toString() ?? ''
                )
              )
            }
          >
            Max
          </Button>
          <Button
            className='h-8 rounded bg-secondary-2 hover:bg-primary text-primary hover:text-secondary-2 transition-all w-full px-4 py-2 min-w-20 font-medium text-14 flex justify-center items-center gap-2'
            type='button'
            onClick={() => setValue('amount', '')}
          >
            <img src='/assets/images/icons/Ic_delete_other.png' alt='Delete' title='Delete' width='24' height='24' />
          </Button>
        </div>
        {(wallet === 'SPORT_WALLET' && !is_enable_sports) || (wallet === 'CASINO_SLOT_WALLET' && !is_enable_casino) ? (
          <div className='h-10 rounded bg-primary text-white w-fit px-4 min-w-[180px] font-medium text-14 mt-10 mb-[30px] flex justify-center items-center'>
            환전하기
          </div>
        ) : (
          <Button
            className='h-10 rounded bg-primary text-white w-fit px-4 min-w-[180px] font-medium text-14 mt-10 mb-[30px]'
            type='submit'
          >
            환전하기
          </Button>
        )}
      </div>
    </form>
  )
}
