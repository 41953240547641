import { useQuery } from '@apollo/client'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { pathMiniGameType } from 'src/constants/path'
import { GET_SCORE_POWER_LADDER_GAME } from 'src/libs/apis/graphql/queries/mini_game.query'
import { setRefetchHistoryBetting } from 'src/libs/stores/minigame.reducer'
import { GameReq, ScorePowerLadderQuery } from 'src/types/minigame.type'

export const useScorePowerGame = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { data, loading, refetch } = useQuery<ScorePowerLadderQuery, GameReq>(GET_SCORE_POWER_LADDER_GAME, {
    context: {
      apiName: 'mini-game'
    },
    variables: {
      game: pathMiniGameType[location.pathname]
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      if (!data?.ScoreQuery.round_id) {
        toast.error('Game is not available')
      }
      dispatch(setRefetchHistoryBetting(false))
    }
  })

  const handleRefetchGame = async () => {
    await refetch()
  }
  useEffect(() => {
    handleRefetchGame()
  }, [location.pathname])

  return {
    data,
    loading,
    refetch,
    handleRefetchGame
  }
}
