export const dataButton = [
  {
    name: '1만원',
    value: 10000
  },
  {
    name: '5만원',
    value: 50000
  },
  {
    name: '10만원',
    value: 100000
  },
  {
    name: '20만원',
    value: 200000
  },
  {
    name: '30만원',
    value: 300000
  },
  {
    name: '50만원',
    value: 500000
  },
  {
    name: '100만원',
    value: 1000000
  },
  {
    name: '500만원',
    value: 5000000
  },
  {
    name: '1000만원',
    value: 10000000
  }
]
