import { gql } from '@apollo/client'

export const ROULETTE = gql`
  query Roulette {
    roulette {
      id
      bgcolor
      amount
    }
  }
`
