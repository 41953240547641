import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'src/libs/utils/store'

export const useSportSearchStore = () => {
  const {
    eventId,
    dataRateInfoList,
    dataSportSearch,
    dataSportSearchPure,
    isLoadingRateInfo,
    isLoadingSports,
    isOpenBoardBetSport,
    isFirstTimeFetch,
    rules,
    exchangeRateInfoList
  } = useSelector((state: RootState) => state.sportSearch)

  function getRulesBySportsIdx(sportIdx: number) {
    return rules.find((rule) => rule.sports_code === sportIdx) || null
  }

  const objExchangeRateInfo = useMemo(() => {
    return exchangeRateInfoList.reduce<{ [key: string | number]: number }>((acc, cur) => {
      if (!acc[cur.sports_code]) {
        acc[cur.sports_code] = cur.rate_deduct
      }
      return acc
    }, {})
  }, [exchangeRateInfoList])

  const sportData = useMemo(() => {
    return dataSportSearchPure.find((item) => item.idx === +eventId)
  }, [dataSportSearchPure, eventId])

  return {
    eventId,
    dataRateInfoList,
    dataSportSearch,
    dataSportSearchPure,
    isLoadingSports,
    isLoadingRateInfo,
    isOpenBoardBetSport,
    isFirstTimeFetch,
    rules,
    getRulesBySportsIdx,
    objExchangeRateInfo,
    sportData
  }
}
