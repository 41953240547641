import React, { useEffect } from 'react'

export const IframeLiveStream = ({ title }: { title: string }) => {
  useEffect(() => {
    const iframe = document.getElementById('vframe1') as HTMLIFrameElement
    if (iframe) {
      iframe.height = `${(iframe.offsetWidth * 9) / 16}px`
    }
  }, [])

  return (
    <div>
      <iframe
        width='648'
        height='365'
        id='vframe1'
        src={`https://vet3.com/video?q=high&player=1&autostart=true&title=${title}`}
        // src='https://vet3.com/video?q=high&player=1&autostart=true&title=Euro+Cup'
        frameBorder='0'
        scrolling='no'
        allowFullScreen
        title='live stream'
      ></iframe>
    </div>
  )
}
