import cn from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { koreanImages } from 'src/components/SideRightMiniGame/SideRightMiniGame'
import { useCartMiniGameStore } from 'src/libs/hooks/store/useCartMiniGameStore'
import { useViewport } from 'src/libs/hooks/useWindowDimension'
import { setShowCart } from 'src/libs/stores/common.reducer'
import { addItemToCartMiniGame, BetMiniGameItem, removeAllCartMiniGame } from 'src/libs/stores/minigame.reducer'
import {
  convertMapToArrayMiniGame,
  formatTimeFromSeconds,
  groupDataOptionsByCategory,
  groupDataOptionsByTab
} from 'src/libs/utils/minigame.func'
import { GameRes, OptionMiniGameItem } from 'src/types/minigame.type'

type Props = {
  data: GameRes
  loading?: boolean
  handleRefetchGame: () => Promise<void>
}

export const BetItemSurePowerBall = (data: {
  title: string
  listOption: OptionMiniGameItem[]
  transId: string
  isLock: boolean
}) => {
  const { cart } = useCartMiniGameStore()
  const dispatch = useDispatch()
  const { width } = useViewport()

  const addToCart = ({ betMiniGameItem }: { betMiniGameItem: BetMiniGameItem }) => {
    if (data.isLock) {
      toast.error('Locking time')
      return
    }
    if (width < 1280) {
      dispatch(setShowCart(true))
    }
    // implement when BE implement multi choose in 1 request
    // if (cart.pick_list.length === MAXIMUM_BET_ONE_TIME) {
    //   toast.warning('Can not pick')
    // }
    dispatch(addItemToCartMiniGame(betMiniGameItem))
  }
  return (
    <div className='grid grid-cols-1 md-up:grid-cols-[2fr_10fr] gap-4 py-1 px-2'>
      <div className='cols-span-2 flex text-center items-center justify-center bg-mini-game-table-4 text-mini-game-table-2 text-14'>
        {data.title.split(' ').map((word, index) => (
          <React.Fragment key={index}>
            {word}
            {index < data.title.split(' ').length - 1 && <br />}
          </React.Fragment>
        ))}
      </div>
      <div
        className='cols-span-10 grid  gap-x-6 gap-y-3'
        style={{
          gridTemplateColumns: `repeat(${data.listOption.length > 5 ? 5 : data.listOption.length}, 1fr)`
        }}
      >
        {data.listOption.map((option) => {
          //           const isOptionMultiple = option.text ? option.text?.split(' ').length > 1 : false
          // console.log("isOptionMultiple",isOptionMultiple)
          //           if (isOptionMultiple) {
          //             return (
          //               <button
          //                 onClick={() => {
          //                   addToCart({
          //                     betMiniGameItem: {
          //                       selectedKeyItem: option.key || '',
          //                       miniGameItem: {
          //                         category: option.category_text || '',
          //                         rate: option.rate || 0,
          //                         text: option.text || '',
          //                         transId: data.transId
          //                       }
          //                     }
          //                   })
          //                 }}
          //                 key={option.key}
          //                 className={cn(
          //                   'flex flex-col items-center justify-center h-24 gap-1 border border-mini-game-table-border',
          //                   {
          //                     'bg-mini-game-table-item hover:bg-mini-game-table-item-active':
          //                       !cart.pick_list.length ||
          //                       (cart.pick_list.length && cart.pick_list[0].selectedKeyItem !== option.key),
          //                     'bg-mini-game-table-item-active':
          //                       cart.pick_list.length && cart.pick_list[0].selectedKeyItem === option.key,
          //                     '!h-16': data.listOption.length > 5
          //                   }
          //                 )}
          //               >
          //                 <div className='flex items-center justify-center'>
          //                   {option.text?.split(' ').map((item, indexText, textArray) => {
          //                     if (indexText === textArray.length - 1) {
          //                       return (
          //                         <div
          //                           key={indexText}
          //                           className={cn('size-8 p-2 flex items-center justify-center rounded-full', {
          //                             '!size-4': data.listOption.length > 5
          //                           })}
          //                           style={{
          //                             backgroundColor: option.color?.split(' ')[indexText]
          //                           }}
          //                         >
          //                           <span className='text-12 font-bold'>{item}</span>
          //                         </div>
          //                       )
          //                     }
          //                     return (
          //                       <React.Fragment key={indexText}>
          //                         <div
          //                           className={cn('size-8 p-2 flex items-center justify-center rounded-full', {
          //                             '!size-[30px] !p-1': data.listOption.length > 5
          //                           })}
          //                           style={{
          //                             backgroundColor: option.color?.split(' ')[indexText]
          //                           }}
          //                         >
          //                           <span className='text-12 font-bold'>{item}</span>
          //                         </div>
          //                         <div className='flex items-center justify-center rounded-full'>
          //                           <PlusLineIcon className='size-7 text-mini-game-table-3' />
          //                         </div>
          //                       </React.Fragment>
          //                     )
          //                   })}
          //                 </div>
          //               </button>
          //             )
          //           }
          return (
            <button
              onClick={() => {
                addToCart({
                  betMiniGameItem: {
                    selectedKeyItem: option.key || '',
                    miniGameItem: {
                      category: option.category_text || '',
                      rate: option.rate || 0,
                      text: option.text || '',
                      transId: data.transId
                    }
                  }
                })
              }}
              key={option.key}
              className={cn(
                'flex items-center justify-center h-24 gap-2 border border-mini-game-table-border relative group duration-150',
                {
                  'bg-mini-game-table-item hover:bg-mini-game-table-item-active':
                    !cart.pick_list.length ||
                    (cart.pick_list.length && cart.pick_list[0].selectedKeyItem !== option.key),
                  'bg-mini-game-table-item-active is-active':
                    cart.pick_list.length && cart.pick_list[0].selectedKeyItem === option.key,
                  '!h-20 pt-3': data.listOption.length > 5
                }
              )}
            >
              {option.link ? (
                <div
                  className={cn('flex flex-col items-center gap-1 justify-center rounded-full mt-8', {
                    'w-[30] !p-0 !mt-5': data.listOption.length > 5
                  })}
                  style={{
                    backgroundColor: option.color
                  }}
                >
                  <img src={option.link} alt='' className='w-[44px] h-3' />
                  <span className='line-clamp-1 whitespace-nowrap text-mini-game-table-3 text-14 font-bold group-[.is-active]:text-white duration-150'>
                    {option.text}
                  </span>
                </div>
              ) : !['대', '중', '소'].includes(option.text) ? (
                <img src={koreanImages[option?.text]} alt='' className='size-10 mt-8' />
              ) : (
                <div
                  className={cn('relative size-10 p-2 mt-4 flex items-center justify-center rounded-full', {
                    '!size-[30px] !p-0': data.listOption.length > 5
                  })}
                >
                  <span className='line-clamp-1 whitespace-nowrap text-mini-game-table-3 text-14 font-bold group-[.is-active]:text-white duration-150'>
                    {option.text}
                  </span>
                </div>
              )}
              <div className='absolute w-full h-8 flex justify-center items-center bg-mini-game-table-3 top-0 group-hover:z-10 group-[.is-active]:z-10 text-12'>
                {option.rate?.toFixed(2)}
              </div>
              {['대', '중', '소'].includes(option.text) && (
                <img
                  src={koreanImages[option.text]}
                  alt=''
                  className='bottom-0 left-0 absolute group-hover:opacity-0'
                />
              )}
            </button>
          )
        })}
      </div>
    </div>
  )
}

export const BoardBetMiniGameSurePowerBall = ({ data, loading = false }: Props) => {
  const [countdown, setCountdown] = useState<number | null>(
    data.end_time - data.now > 0 ? data.end_time - data.now : null
  )
  const timerServer = useMemo(
    () => ({
      startTime: data.start_time,
      endTime: data.end_time,
      now: data.now
    }),
    [data]
  )
  const [isLockBet, setIsLockBet] = useState(data.end_time - data.now < 0)
  const dispatch = useDispatch()
  // const fakeData: OptionMiniGameItem[] = useMemo(
  //   () =>
  //     data.data.map((item) => {
  //       const random = Math.random() < 0.5 ? 'tab1' : 'tab2'
  //       return {
  //         ...item,
  //         tab: random,
  //         tab_text: random
  //       }
  //     }),
  //   []
  // )
  const [tabActive, setTabActive] = useState(data.data[0].tab_text)

  const groupByTabs = groupDataOptionsByTab(data.data)
  const groupCategoryByTab: { [key: string]: Map<string, OptionMiniGameItem[]> | null } = {}
  const groupCategoryByTabObj: {
    [key: string]: {
      key: string
      value: OptionMiniGameItem[]
    }[]
  } = {}
  let groupByCategory = null
  let isTabsExist = false
  let groupByTabsArr: {
    key: string
    value: OptionMiniGameItem[]
  }[] = []
  if (groupByTabs !== null) {
    isTabsExist = true
    groupByTabsArr = convertMapToArrayMiniGame(groupByTabs)
    groupByTabsArr.forEach((tabs) => {
      groupCategoryByTab[tabs.key] = groupDataOptionsByCategory(tabs.value)
      if (groupCategoryByTab[tabs.key] !== null) {
        groupCategoryByTabObj[tabs.key] = convertMapToArrayMiniGame(groupCategoryByTab[tabs.key]!)
      }
    })
    // console.log(groupCategoryByTabObj)
  } else {
    groupByCategory = groupDataOptionsByCategory(data.data)
  }

  useEffect(() => {
    if (timerServer.endTime && countdown && countdown <= data.blocking_time) {
      setIsLockBet(true)
      dispatch(removeAllCartMiniGame())
    }
    if (countdown && countdown < 0) {
      setCountdown(null)
    }
  }, [countdown, timerServer])

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prev) => {
        if (prev !== null) {
          return prev - 1
        }

        return prev
      })
    }, 1000)
    return () => clearInterval(interval)
  }, [data])
  useEffect(() => {
    setCountdown(data.end_time - data.now)
    if (data.end_time - data.now > data.blocking_time) {
      setIsLockBet(false)
    }
  }, [data])
  useEffect(() => {}, [tabActive])

  if (groupByCategory === null && isTabsExist === false) {
    // cut'
    return
  }
  const groupByCategoryArr = isTabsExist
    ? groupCategoryByTabObj[tabActive!]
    : groupByCategory !== null
      ? convertMapToArrayMiniGame(groupByCategory)
      : []
  return (
    <div className='flex flex-col gap-1 relative h-full'>
      {isLockBet && (
        <div className='absolute w-full h-full z-10 bg-black/60 flex items-center justify-center'>
          <img src='/assets/images/recharge/block-icon.png' alt='block' title='block' width='50' height='50' />
        </div>
      )}
      <div className='grid grid-cols-2 gap-1 h-[50px] py-1 px-2'>
        <div className='grid grid-cols-[5fr_8fr]'>
          <span className='cols-span-5 bg-mini-game-table-3 flex items-center justify-center text-14 text-mini-game-table-1 text-nowrap'>
            회차
          </span>
          <span className='cols-span-8 bg-mini-game-table-4 flex items-center justify-center text-white font-bold'>
            {data?.round_id?.split('_').length > 1 ? data?.round_id?.split('_')[1] : data?.round_id}
          </span>
        </div>
        <div className='grid grid-cols-[5fr_8fr]'>
          <span className='cols-span-5 bg-mini-game-table-3 flex items-center justify-center text-14 text-mini-game-table-1 text-nowrap'>
            배팅마감
          </span>
          <span className='cols-span-8 bg-mini-game-table-4 flex items-center justify-center text-white font-bold'>
            {loading ? '--:--' : countdown ? formatTimeFromSeconds(countdown) : '--:--'}
          </span>
        </div>
      </div>
      <div className='w-full h-px bg-mini-game-table-4'></div>
      {isTabsExist && (
        <>
          <div className='grid grid-cols-2 gap-8 h-24 py-1 px-5'>
            {groupByTabsArr.map((item) => {
              return (
                <button
                  key={item.key}
                  className={cn(
                    'cols-span-4 bg-mini-game-table-3 flex items-center justify-center text-14 text-mini-game-table-1 rounded-xl border border-mini-game-table-item',
                    {
                      '!border-mini-game-table-1': tabActive === item.key
                    }
                  )}
                  onClick={() => {
                    setTabActive(item.key)
                  }}
                >
                  {item.key}
                </button>
              )
            })}
            {/* <button
              className={cn(
                'cols-span-4 bg-mini-game-table-3 flex items-center justify-center text-14 text-mini-game-table-1 rounded-xl border',
                {
                  'border-mini-game-table-item': tabActive === 'tab1'
                }
              )}
              onClick={() => {
                setTabActive('tab1')
              }}
            >
              회차
            </button>
            <button
              className={cn(
                'cols-span-4 bg-mini-game-table-3 flex items-center justify-center text-14 text-mini-game-table-1 rounded-xl border',
                {
                  'border-mini-game-table-item': tabActive === 'tab2'
                }
              )}
              onClick={() => {
                setTabActive('tab2')
              }}
            >
              회차
            </button> */}
          </div>
          <div className='w-full h-px bg-mini-game-table-4'></div>
        </>
      )}
      <div className='flex flex-col gap-2'>
        {groupByCategoryArr?.map((category, index) => {
          if (index === groupByCategoryArr.length - 1) {
            return (
              <BetItemSurePowerBall
                isLock={isLockBet}
                transId={data?.round_id}
                key={index}
                listOption={category.value}
                title={category.key}
              />
            )
          }
          return (
            <React.Fragment key={index}>
              <BetItemSurePowerBall
                isLock={isLockBet}
                transId={data?.round_id}
                key={index}
                listOption={category.value}
                title={category.key}
              />
              <div className='w-full h-px bg-mini-game-table-4'></div>
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}
