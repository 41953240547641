import { gql } from '@apollo/client'

const WithdrawMutation = gql`
  mutation WithdrawMutation($amount: Int, $wallet: String) {
    WithdrawMutation(amount: $amount, wallet: $wallet) {
      miNo
      miType
      miStatus
      miBankMoney
      miRegDate
      mProcessDate
    }
  }
`

const RechargeMutation = gql`
  mutation RechargeMutation($amount: Int, $wallet: String, $bonus: String) {
    RechargeMutation(amount: $amount, wallet: $wallet, bonus: $bonus) {
      miNo
      miType
      miStatus
      miBankMoney
      miRegDate
      mProcessDate
    }
  }
`

const DeleteMoneyInfoMutation = gql`
  mutation DeleteMoneyInfoMutation($id: Int) {
    DeleteMoneyInfoMutation(id: $id)
  }
`

const MOVE_MONEY_MUTATION = gql`
  mutation ExchangeMoneyMutation($from: String, $to: String, $amount: Float) {
    ExchangeMoneyMutation(from: $from, to: $to, amount: $amount) {
      mNo
      mID
      mNick
      mRealName
      mLevel
      mPhone
      mStatus
      mPoint
      mMoney
      mSportsMoney
      mBankName
      mBankNumber
      mBankOwner
      mMemberID
      accessToken
    }
  }
`

export const DELETE_MONEY_INFO_BY_PERIOD_MUTATION = gql`
  mutation DeleteByDays($type: String, $days: Int) {
    DeleteByDays(type: $type, days: $days)
  }
`

export { DeleteMoneyInfoMutation, MOVE_MONEY_MUTATION, RechargeMutation, WithdrawMutation }

