/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
// import LoginModal from "src/components/Modal/LoginModal"

import { Spin } from 'antd'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import path from 'src/constants/path'
import { useAdminConfig } from 'src/libs/hooks/useAdminConfig'
import { useEventQuery, useNoticeQuery } from 'src/libs/hooks/useNotice'
import { useViewport } from 'src/libs/hooks/useWindowDimension'
import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import ListProvider from './ListProvider'
import LogoProvider from './LogoProvider'
import EventZoneBanner from './components/EventZoneBanner'
import { ArrowUpIcon } from 'src/components/Icons'
import { isDev } from 'src/libs/utils/env'
import { GET_PAGE_SITE_QUERY } from 'src/libs/apis/graphql/queries/page.query'
import { useQuery } from '@apollo/client'

export default function HomePage() {
  const navigate = useNavigate()
  const [categoryId, setCategoryId] = useState('notice')
  const searchParams = new URLSearchParams(window.location.search)
  const { bannerSettings, sidebarSettings } = useAdminConfig()
  const { data, loading: noticeLoading } = useNoticeQuery({ filter: categoryId })
  const { data: eventData, loading: eventLoading } = useEventQuery()
  const { data: pageSiteQuery } = useQuery(GET_PAGE_SITE_QUERY, {
    context: { apiName: 'auth' }
  })
  const { categoryNotice, noticeType } = data || {}
  const { noticeType: eventType } = eventData || {}
  const { banners } = bannerSettings || {}
  const { mobileLinks } = sidebarSettings || {}
  const { centerBelow: centerBellowBanners, center: centerBanners, right: rightBanners } = banners || {}

  const { isIP } = useViewport()

  const getDevSlidersDemoLeft = () => {
    if (isDev) {
      return [{ image: '/assets/images/banner_homepage/banner-left.png', link: '/home', target: '_blank' }]
    }

    return centerBanners
  }

  const getDevSlidersDemoRight = () => {
    if (isDev) {
      return [
        { image: '/assets/images/banner_homepage/banner-right.png', link: '/home', target: '_blank' },
        { image: '/assets/images/banner_homepage/banner-right-1.png', link: '/home', target: '_blank' },
        { image: '/assets/images/banner_homepage/banner-right-2.png', link: '/home', target: '_blank' }
      ]
    }

    return centerBanners
  }

  const navigateToEvent = (eventId: number, categoryId: string) => {
    searchParams.set('event', eventId?.toString() || '0')
    searchParams.set('category', categoryId)

    navigate({ pathname: path.event, search: searchParams.toString() })
  }

  return (
    <section className={` ${isIP ? 'h-[95dvh]' : 'h-[90dvh]'} overflow-y-auto sidebar__overflow m-1 pb-16`}>
      {/* <LoginModal open={true}/> */}
      {/* {centerBanners && centerBanners?.length > 0 && (
        <div className='w-full  aspect-[325/77] min-h-[150px] overflow-hidden'>
          <Swiper
            slidesPerView={1}
            className='h-full'
            modules={[Pagination]}
            pagination={{ bulletActiveClass: 'bg-primary-2 opacity-100', clickable: true }}
          >
            {centerBanners?.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <img src={item?.image} alt={item?.image} title={item?.image} className='w-full h-full object-cover' />
                  <Link
                    to={item?.link}
                    target={item?.target ? '_blank' : '_self'}
                    className='absolute top-0 left-0 w-full h-full'
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      )} */}
      <div className='md:hidden'>
        <div className='px-3'>
          <EventZoneBanner Pagination={[Pagination]} centerBanners={getDevSlidersDemoLeft()} />
        </div>
        <div className='grid grid-cols-3 gap-2 py-4 px-2'>
          {mobileLinks?.map((item, index) => (
            <Link to={item.link} key={index}>
              <div className='flex flex-col items-center gap-1'>
                <img src={item?.image} alt={item?.key} title={item?.key} className='w-full' />
                {/* <p className='text-12'>{item.name}</p> */}
              </div>
            </Link>
          ))}
        </div>
      </div>
      <div className='hidden md:block'>
        <EventZoneBanner Pagination={[Pagination]} centerBanners={getDevSlidersDemoLeft()} />
      </div>
      <div className='hidden md:grid lg:grid-cols-2 gap-2 min-h-[265px] lg:mt-4 md:px-4 xl:px-1 font-custom'>
        <div className='pb-5 px-2 lg:pl-1 lg:pr-2 bg-[#14161b] rounded-lg'>
          <div className='flex justify-between flex-col lg:flex-row gap-4 pt-2 border-b-2 border-[#292c33] -ml-1 -mr-2'>
            <div className='flex items-center justify-between gap-2 p-3 w-full rounded-lg'>
              <div className='flex items-center'>
                <p className='text-18 font-bold pl-1 text-[#dfdfdf]'>공지사항 </p>
              </div>
              <div>
                <Link to={path.event} title='More' className='text-18'>
                  <i className='fas fa-plus mr-1 text-[16px]' />
                  MORE
                </Link>
              </div>
            </div>
          </div>
          <div
            className={`px-4 mt-1 h-[265px] overflow-y-auto flex flex-col gap-2 sidebar__overflow  ${noticeLoading && 'justify-center items-center'}`}
          >
            {noticeLoading ? (
              <Spin />
            ) : (
              <ul className='list-tab list-none text-14'>
                {noticeType?.data?.map((item, index) => (
                  <Link to={{ pathname: path.new_board, search: `noticeId=${item?.ntNo}` }} key={index}>
                    <li className='py-2 cursor-pointer flex items-center'>
                      {/* <img src='/default-notice.png' className='mr-6' width={30} alt='' /> */}
                      <div className='size-2 bg-primary mr-2' />
                      <div className='flex gap-1 items-start flex-auto'>
                        <span className='text-[#0dbffe] flex-auto'>
                          <span className='text-white text-14'>{item?.ntTitle}</span>
                        </span>
                      </div>
                    </li>
                  </Link>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div className='pb-5 px-2 lg:pl-1 lg:pr-2 bg-[#14161b] rounded-lg'>
          <div className='flex justify-between flex-col lg:flex-row gap-4 pt-2 border-b-2 border-[#292c33] -ml-1 -mr-2'>
            <div className='flex items-center justify-between gap-2 p-3 w-full rounded-lg'>
              <div className='flex items-center'>
                <p className='text-18 font-bold pl-1'>이벤트</p>
              </div>
              <div>
                <Link to={path.event} title='More' className='text-18'>
                  <i className='fas fa-plus mr-1 text-[16px]' />
                  MORE
                </Link>
              </div>
            </div>
          </div>
          <div
            className={`px-4 mt-[5px] h-[265px] overflow-y-auto sidebar__overflow flex ${eventLoading && 'justify-center items-center'}`}
          >
            {eventLoading ? (
              <Spin />
            ) : (
              <ul className='list-tab list-none text-14 w-full'>
                {eventType?.data?.map((item, index: number) => (
                  <Link to={{ pathname: path.event, search: `event=${item?.ntNo}` }} key={index}>
                    <li className='py-2 cursor-pointer flex items-center'>
                      <div className='size-2 bg-primary mr-2' />
                      <div className='flex gap-1 items-start flex-auto'>
                        <span className='text-yellow-1 flex-auto'>
                          <span className='text-white text-14'>{item?.ntTitle}</span>
                        </span>
                      </div>
                    </li>
                  </Link>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
      {/* <hr className='hr-line' /> */}
      <div className='hidden lg:block'>
        <ListProvider listProvider={centerBellowBanners || []} />
        {/* <LogoProvider />
        <div className='flex justify-center items-center py-10 gap-4 flex-col xl:flex-row'>
          <div className='flex flex-wrap justify-center items-center gap-4'>
            <img
              src='/assets/images/home/provider/mga-logo.png'
              alt='mga'
              title='mga'
              width='264'
              height='50'
              className='opacity-20 hover:opacity-100 transition-opacity'
            />
            <img
              src='/assets/images/home/provider/sportcore-logo.png'
              alt='mga'
              title='mga'
              width='284'
              height='66'
              className='opacity-20 hover:opacity-100 transition-opacity'
            />
          </div>
        </div> */}
      </div>

      {/* <div className='pb-4'>
        <img
          src='/assets/images/home/provider/18-logo.png'
          alt='18'
          title='18'
          width='100'
          height='119'
          className='mx-auto'
        />
      </div> */}
      <div className='flex flex-col gap-1 md:hidden px-3'>
        {rightBanners?.map((item, index: number) => (
          <Link key={index} to={item?.link} target={item?.target ? '_blank' : '_self'}>
            <img src={item?.image} alt='' title='' width='294' height='100' className='w-full' />
          </Link>
        ))}
      </div>
      <div className='w-full px-3 pb-3'>
        <p className='text-12 text-center'>
          Copyright © {pageSiteQuery?.PageSiteQuery?.solution_name || ''} All Rights Reserved.
        </p>
      </div>
    </section>
  )
}
