import { gql } from '@apollo/client'

export const BET_MUTATION = gql`
  mutation SportBetMutation(
    $bet_cash: Int
    $game_type: Int
    $pick_list: [PickInputType]!
    $folders_bonus: FoldersBonusInputType!
  ) {
    SportBetMutation(bet_cash: $bet_cash, game_type: $game_type, pick_list: $pick_list, folders_bonus: $folders_bonus) {
      status
      message
    }
  }
`
export const UPDATE_BET_MUTATION = gql`
  mutation UpdatePickMutation($pick_list: [PickInputType]!) {
    UpdatePickMutation(pick_list: $pick_list) {
      data {
        bet_code
        select_rate
        status
      }
      status
      message
    }
  }
`
export const CANCEL_BET_MUTATION = gql`
  mutation CancelBetMutation($bet_id: Int) {
    CancelBetMutation(bet_id: $bet_id) {
      status
      message
      data {
        mSportsMoney
      }
    }
  }
`

export const CANCEL_BET_HISTORY_MUTATION = gql`
  mutation CancelBetMutation($cart_idx: String) {
    CancelBetMutation(cart_idx: $cart_idx) {
      status
      message
      data {
        mSportsMoney
      }
    }
  }
`

export const DELETE_BET_MUTATION = gql`
  mutation DeleteBetMutation($bet_id: Int) {
    DeleteBetMutation(bet_id: $bet_id)
  }
`
