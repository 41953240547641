import { useQuery } from '@apollo/client'
import { Modal as AntModal, ModalProps } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { GET_PAGE_SITE_QUERY } from 'src/libs/apis/graphql/queries/page.query'
import { setCloseAuthModal } from 'src/libs/stores/auth.reducer'
import { RootState } from 'src/libs/utils/store'
import { convertAdminAssetUrl } from 'src/libs/utils/url'
import './index.css'

interface IAuthModalProps extends Omit<ModalProps, 'open'> {
  typeModal: 'login' | 'register' | 'advisory'
}

const CloseIcon = () => {
  return (
    <img
      className='rounded-sm transition-all '
      src='/assets/images/icons/close-auth-modal-login.svg'
      alt='close-modal'
    />
  )
}

const AuthModal = ({ typeModal, ...props }: IAuthModalProps) => {
  const dispatch = useDispatch()
  const isOpen = useSelector((state: RootState) => state.auth.authModal.isOpen)
  const { data: pageSiteQuery } = useQuery(GET_PAGE_SITE_QUERY, {
    context: { apiName: 'auth' }
  })

  const handleClose = () => {
    dispatch(setCloseAuthModal())
  }

  const isLoginModal = typeModal === 'login'
  const hiddenLogoRegister = !isLoginModal && true

  return (
    <AntModal
      {...props}
      footer={null}
      className={`auth-modal ${!isLoginModal && '!w-max'}`}
      closeIcon={<CloseIcon />}
      open={isOpen}
      onCancel={handleClose}
      transitionName=''
      destroyOnClose={true}
      classNames={{
        body: '!pb-[20px]'
      }}
    >
      <div className='flex flex-col justify-center items-center text-center'>
        <div className='flex flex-col items-center text-white font-bold text-[21px] my-3 gap-2'>
          {/* {!hiddenLogoRegister && pageSiteQuery?.PageSiteQuery?.siLogo1 && (
            <img
              src={pageSiteQuery?.PageSiteQuery?.siLogo1}
              alt='logo'
              className='max-w-[136px] object-cover'
              onError={(e) => {
                e.currentTarget.src = '/zero.png'
                e.currentTarget.onerror = null
              }}
            />
          )} */}
          {pageSiteQuery?.PageSiteQuery?.solution_name || ''} {isLoginModal ? '로그인' : '회원가입'}
        </div>
        <div className={`content_login_modal px-4 ${!isLoginModal ? 'flex gap-8' : 'w-full'}`}>
          {/* {!isLoginModal && (
            <div className='bg-secondary flex-shrink-0'>
              <img
                className='hidden lg:block h-full min-w-[200px]'
                src='https://play-222.com/image/login/signup-yellow15.png'
                alt=''
              />
            </div>
          )} */}
          <div className='form-container'>{props?.children}</div>
        </div>
      </div>
    </AntModal>
  )
}

export default AuthModal
