import { useEffect, useRef, useState } from 'react'
import path, { pathMiniGameType } from 'src/constants/path'
import { useViewport } from 'src/libs/hooks/useWindowDimension'
import { NotFoundState } from './NotFoundState'
import { BoardBetMiniGameBePick } from './BoardBetMiniGameBePick'
import { useBePickGame } from './useBePickGame'
import { useEventMiniGame } from '../useEventMiniGame'
import { ConfigProvider, TableColumnsType, TablePaginationConfig } from 'antd'
import { formatCurrency } from 'src/libs/utils/utilFuncs'
import { DetailMiniGameBetting, ResultMiniGameHistory } from 'src/types/minigame.type'
import { GET_MINI_GAME_BET_HISTORY } from 'src/libs/apis/graphql/queries/game.query'
import { useQuery } from '@apollo/client'
import { setRefetchHistoryBetting } from 'src/libs/stores/minigame.reducer'
import { useDispatch, useSelector } from 'react-redux'
import DataTable from 'src/components/Table/DataTable'
import { useLocation, useOutletContext } from 'react-router-dom'
import { Menu } from '../MiniGame'
import { RootState } from 'src/libs/utils/store'
import { PageSiteQueryType } from 'src/types/common.type'
import { GET_PAGE_SITE_QUERY } from 'src/libs/apis/graphql/queries/page.query'

const MAP_IFRAME_URL: { [key: string]: string } = {
  [path.mini_sure_power_ball1]: 'https://www.BePick.com/?id=one',
  [path.mini_sure_power_ball2]: 'https://www.BePick.com/?id=two',
  [path.mini_sure_power_ball3]: 'https://www.BePick.com/?id=thr'
}

/* eslint-disable jsx-a11y/iframe-has-title */
export default function BePick() {
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const { menu } = useOutletContext<{ menu: Menu[] }>()
  const subMenus = menu.map((i) => [...i.subMenu]).flat()

  const iframeLink2 = subMenus.find((i) => i.path === location.pathname)?.link || ''
  const { data: pageSiteQuery } = useQuery<PageSiteQueryType>(GET_PAGE_SITE_QUERY, {
    context: { apiName: 'auth' }
  })
  const { data, loading, handleRefetchGame } = useBePickGame()
  const { width } = useViewport()
  useEventMiniGame({ callback: handleRefetchGame })
  const dispatch = useDispatch()
  const [query, setQuery] = useState({ limit: 10, page: 1, total: 0 })
  const [miniHistoryBet, setMiniHistoryBet] = useState<DetailMiniGameBetting[]>([])
  const {
    data: miniGameBetData,
    refetch: refetchMiniGameBetData,
    loading: loadingHistory
  } = useQuery<ResultMiniGameHistory>(GET_MINI_GAME_BET_HISTORY, {
    variables: { limit: query.limit ?? 10, page: 1, category: pathMiniGameType[location.pathname] },
    context: { apiName: 'mini-game' },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setMiniHistoryBet(data.MiniGameBettingList?.data)
      setQuery((prev) => ({
        ...prev,
        total: data?.MiniGameBettingList?.total
      }))
      dispatch(setRefetchHistoryBetting(false))
    }
  })

  const columns: TableColumnsType<DetailMiniGameBetting> = [
    {
      title: '시간',
      dataIndex: 'tmRegDate',
      key: 'tmRegDate',
      width: '180px',
      align: 'center',
      render: (_, record) => {
        return record.tmRegDate
      }
    },
    {
      title: '배팅정보',
      dataIndex: 'tmProviderGame',
      key: 'tmProviderGame',
      // width: '80px',
      align: 'center'
    },
    {
      title: '배팅액',
      dataIndex: 'tmBetAmount',
      key: 'tmBetAmount',
      // width: '80px',
      align: 'center',
      render: (value) => {
        // return formatCurrency(Number(value.toFixed(0)) || 0) + ' 원'
        return formatCurrency(value || 0)
      }
    },
    {
      title: '배팅접수',
      dataIndex: 'tmSelectedItem',
      key: 'tmSelectedItem',
      // width: '80px',
      align: 'center',
      render: (_, record) => {
        const result = record?.tmSelectedItem
          ? JSON.parse(record.tmSelectedItem)?.color?.split(' ').length > 1
            ? JSON.parse(record.tmSelectedItem)?.color?.split(' ')
            : [record?.tmSelectedItem]
          : []
        // // eslint-disable-next-line no-constant-condition
        // const test = '{"color":"blue red red","text":"\ub099\ucca8 3 \ub099\ucca8"}'
        // const result = test
        //   ? JSON.parse(test)?.color?.split(' ').length > 1
        //     ? JSON.parse(test)?.color?.split(' ')
        //     : [test]
        //   : []
        return result.length > 1 ? (
          <div className='w-full gap-1 flex items-center justify-center'>
            {result.map((item, index) => {
              return (
                <div
                  key={index}
                  className='w-10 h-10 rounded-full flex items-center justify-center'
                  style={{
                    background: item,
                    color: item?.color === 'yellow' ? 'black' : 'white'
                    // background: JSON.parse(test)?.color || 'red'
                  }}
                >
                  <span>
                    {(record?.tmSelectedItem && JSON.parse(record.tmSelectedItem)?.text?.split(' ')[index]) || ''}
                  </span>
                  {/* <span>{JSON.parse(test)?.text?.split(' ')[index] || ''}</span> */}
                </div>
              )
            })}
          </div>
        ) : (
          <div className='w-full flex items-center justify-center'>
            <div
              className='w-10 h-10 rounded-full flex items-center justify-center'
              style={{
                background: JSON.parse(result[0])?.color || 'red',
                color: JSON.parse(result[0])?.color === 'yellow' ? 'black' : 'white'
                // background: JSON.parse(test)?.color || 'red'
              }}
            >
              <span>{JSON.parse(result[0])?.text || ''}</span>
              {/* <span>{(record?.tmSelectedItem && JSON.parse(record?.tmSelectedItem)?.text?.split(' ')[0]) || ''}</span> */}
            </div>
          </div>
        )
      }
    },
    {
      title: '배팅결과',
      dataIndex: 'tmResultBetting',
      key: 'tmResultBetting',
      // width: '80px',
      align: 'center',
      render: (_, record) => {
        const result = record?.tmResultBetting
          ? JSON.parse(record.tmResultBetting)?.color?.split(' ').length > 1
            ? JSON.parse(record.tmResultBetting)?.color?.split(' ')
            : [record?.tmResultBetting]
          : []

        const textExample = record?.tmResultBetting
          ? JSON.parse(record.tmResultBetting)?.text?.split(' ').length > 1
            ? JSON.parse(record.tmResultBetting)?.text?.split(' ')
            : [record?.tmResultBetting]
          : []

        // // eslint-disable-next-line no-constant-condition
        // const test = '{"color":"blue red red","text":"\ub099\ucca8 3 \ub099\ucca8"}'
        // const result = test
        //   ? JSON.parse(test)?.color?.split(' ').length > 1
        //     ? JSON.parse(test)?.color?.split(' ')
        //     : [test]
        //   : []
        return result.length > 1 ? (
          <div className='w-full gap-1 flex items-center justify-center'>
            {result.map((item, index) => {
              return (
                <div
                  key={index}
                  className='w-10 h-10 rounded-full flex items-center justify-center'
                  style={{
                    background: item,
                    color: item?.color === 'yellow' ? 'black' : 'white'
                    // background: JSON.parse(test)?.color || 'red'
                  }}
                >
                  <span>
                    {(record?.tmResultBetting && JSON.parse(record.tmResultBetting)?.text?.split(' ')[index]) || ''}
                  </span>
                  {/* <span>{JSON.parse(test)?.text?.split(' ')[index] || ''}</span> */}
                </div>
              )
            })}
          </div>
        ) : textExample.length > 1 ? (
          <div className='w-full gap-1 flex items-center justify-center'>
            {textExample.map((item, index) => (
              <div
                key={index}
                className='w-10 h-10 rounded-full flex items-center justify-center'
                style={{
                  background: item == 'even' ? 'red' : 'blue',
                  color: item?.color === 'yellow' ? 'black' : 'white'
                }}
              >
                <span>{JSON.parse(record.tmResultBetting)?.text?.split(' ')[index] == 'even' ? '짝' : '홀'}</span>
              </div>
            ))}
          </div>
        ) : (
          <div className='w-full flex items-center justify-center'>
            <div
              className='w-10 h-10 rounded-full flex items-center justify-center'
              style={{
                background: JSON.parse(result[0])?.color || '',
                color: JSON.parse(result[0])?.color === 'yellow' ? 'black' : 'white'
                // background: JSON.parse(test)?.color || 'red'
              }}
            >
              <span>{JSON.parse(result[0])?.text || ''}</span>
              {/* <span>{(record?.tmSelectedItem && JSON.parse(record?.tmSelectedItem)?.text?.split(' ')[0]) || ''}</span> */}
            </div>
          </div>
        )
      }
    },
    {
      title: '적중금액',
      dataIndex: 'tmWinAmount',
      key: 'tmWinAmount',
      // width: '80px',
      align: 'center',
      render: (value) => {
        return formatCurrency(Number(value.toFixed(0)) || 0) + ' 원'
      }
    },
    {
      title: '상태결과',
      dataIndex: 'tmStatus',
      key: 'tmStatus',
      // width: '80px',
      align: 'center',
      render: (_, record) => {
        const result =
          record.tmStatus === 'bet'
            ? '배팅'
            : record.tmStatus === 'win'
              ? '당첨'
              : record.tmStatus === 'draw'
                ? '적특'
                : '낙첨'
        return result
      }
    }
  ]

  const handleChangePagination = (pagination: TablePaginationConfig) => {
    const { current: current_page } = pagination

    setQuery((prev) => ({
      ...prev,
      page: current_page as number
    }))
    refetchMiniGameBetData({ limit: query?.limit || 10, page: current_page || 1 })
  }
  const iframeParentRef = useRef<HTMLIFrameElement>(null)
  const openRightSidebar = useSelector((state: RootState) => state.common.showCart)
  useEffect(() => {
    const handleResize = () => {
      const iframe = iframeRef.current
      const iframeParent = iframeParentRef.current

      if (iframe && iframeParent) {
        const screenWidth = iframeParent.offsetWidth
        const screenHeight = iframeParent.offsetHeight

        // Calculate the aspect ratio
        const aspectRatio = 1252 / 1043
        const parentAspectRatio = screenWidth / screenHeight

        let scaleX, scaleY

        if (parentAspectRatio > aspectRatio) {
          // Parent is wider than the aspect ratio, scale based on height
          scaleY = screenHeight / 1043
          scaleX = scaleY
        } else {
          // Parent is taller than the aspect ratio, scale based on width
          scaleX = screenWidth / 1252
          scaleY = scaleX
        }

        iframe.style.transform = `scale(${scaleX}, ${scaleY})`
        iframe.style.transformOrigin = '0 0'
        iframe.style.margin = 'auto'
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [openRightSidebar, iframeLink2])

  if (!iframeLink2) {
    return
  }

  return (
    <>
      <div className='grid grid-cols-1 md:grid-cols-2 overflow-hidden items-center 3xl:items-stretch'>
        <div className='w-full aspect-[1252/1043]' ref={iframeParentRef}>
          <iframe
            scrolling='no'
            ref={iframeRef}
            src={iframeLink2}
            title='sure racing'
            width={1252}
            height={1043}
            className='absolute'
            // className='!scale-[0.45] xs:!scale-[.5] md:!scale-75 lg:!scale-[.85] 2xl:!scale-[.70] 3xl:!scale-100 3xl-up:!scale-100 relative -left-[220px] -top-[175px] xs:-left-[202px] xs:-top-[170px] md:-left-[20px] md:-top-[70px] lg:left-[80px] lg:-top-[100px] 2xl:-left-[115px] 3xl:-left-[15px] 3xl:-top-[0px] 3xl-up:-left-3 3xl-up:top-0 z-20'
          ></iframe>
        </div>
        <div className='w-full bg-mini-game-table-5'>
          {loading && !data?.BepickQuery ? (
            <div className={`h-[200px]`}>
              <div className='h-full flex items-center justify-center '>
                <img
                  src={pageSiteQuery?.PageSiteQuery?.siLogo1 || '/sample.png'}
                  className='animate-pulse max-w-[120px] max-h-[100px]'
                  alt='logo loading'
                />
              </div>
            </div>
          ) : data?.BepickQuery ? (
            <BoardBetMiniGameBePick handleRefetchGame={handleRefetchGame} data={data.BepickQuery} loading={loading} />
          ) : (
            <NotFoundState />
          )}
        </div>
      </div>
      <div className='mx-2 mt-8 relative table-responsive 2xl:static '>
        <ConfigProvider
          renderEmpty={() => (
            <span className='text-center font-medium text-white'>해당 게임에 배팅내역이 존재하지 않습니다.</span>
          )}
        >
          <DataTable
            columns={columns}
            loading={loadingHistory}
            dataSource={miniHistoryBet as any}
            rowClassName={(record, index) => {
              return index % 2 === 0 ? 'even' : 'odd'
            }}
            pagination={{
              position: ['bottomCenter'],
              total: query.total,
              pageSize: query.limit
            }}
            onChange={handleChangePagination}
          />
        </ConfigProvider>
      </div>
    </>
  )
}
