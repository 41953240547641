import { gql } from '@apollo/client'

export const USE_COUPON_MUTATION = gql`
  mutation RedeemMutation($couponId: String!) {
    RedeemMutation(_id: $couponId)
  }
`

export const USE_BONUS_MUTATION = gql`
  mutation RedeemBonusMutation($type: String!) {
    RedeemBonusMutation(type: $type)
  }
`
