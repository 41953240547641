import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { sportsApi } from 'src/libs/apis/axios/sports.api'
import { useCartSportStore } from 'src/libs/hooks/store/useCartSportStore'
import { useSportSearchStore } from 'src/libs/hooks/store/useSportSearchStore'
import {
  addDataRateInfoList,
  resetDataBoardBet,
  setEventId,
  setFetchApiGetAllRateInfo,
  setOpenBoardBetSport
} from 'src/libs/stores/sport-search.reducer'
import {
  addFolderBonusToCart,
  addItemToCartSport,
  removeAllItemInMatchAndAddNewItem,
  removeItemFromCartSport,
  updateItemFromCartSport
} from 'src/libs/stores/sport.reducer'
import { getTokenDynamic } from 'src/libs/utils/auth'
import { transformDomesticData } from 'src/libs/utils/sports.func'
import { RootState } from 'src/libs/utils/store'
import { Domestic, DomesticWithKeyMatch, FolderBonusItem, SportItemClient } from './../../../types/sports.type'
export const useSelectOptBettingSport = () => {
  const dispatch = useDispatch()
  const { cart, count, limitBetting, getCountItemInMatch, getAllItemInMatch, checkPickedOptionDraw } =
    useCartSportStore()
  const { getRulesBySportsIdx } = useSportSearchStore()
  const user = useSelector((state: RootState) => state.auth.user)
  const readTokenFromLS = useSelector((state: RootState) => state.auth.readTokenFromLS)
  const token = useMemo(() => getTokenDynamic(readTokenFromLS), [user])
  const handleSelectBetting = async ({
    domesticData,
    betId,
    keyMatch,
    rate,
    isDrawOption = false,
    betTeam
  }: {
    domesticData: DomesticWithKeyMatch
    betId: string
    keyMatch: string
    rate: number
    isDrawOption?: boolean
    betTeam: string
  }) => {
    // console.log('domesticData.isWinTieLose', domesticData.isWinTieLose)
    const rules = getRulesBySportsIdx(domesticData.sports_idx)
    const typeRuleFlagPure = rules?.list_limit[0].game_id
    const typeRuleFlag = String(typeRuleFlagPure)
    const listAllowPure = rules?.list_limit[0].list_allow || []
    const listAllow = listAllowPure.map((item) => String(item))
    const cartInMatch = getAllItemInMatch(domesticData)
    const isRowHasOptionDraw = domesticData.isWinTieLose
    const categoryUserPick = isDrawOption ? domesticData.betid2 : domesticData.game_id
    const isPickedOptionDraw = checkPickedOptionDraw(cartInMatch)

    if (rules?.is_can_bet_draw && isRowHasOptionDraw) {
      listAllow.push(domesticData.betid2)
    }
    if (cartInMatch.length === 0) {
      const totalRate = cart.pick_list.reduce((rateTotal, curr) => rateTotal * Number(curr.rate), 1)
      const incomingTotalRate = totalRate * Number(rate)

      if (incomingTotalRate > (limitBetting?.BettingLimitsSportQuery.max_payout || 0)) {
        toast.error('최대배당을 초과하였습니다.')
        return
      }

      dispatch(addItemToCartSport({ domesticData, betId, rate, betTeam, keyMatch }))
      return
    }

    const isChosenFromBoard = cartInMatch.some((item) => item.domesticData.isChosenFromBoard)
    if (isChosenFromBoard) {
      dispatch(
        removeAllItemInMatchAndAddNewItem({
          keyMatch,
          dateBet: {
            domesticData,
            betId,
            keyMatch,
            rate,
            betTeam
          }
        })
      )
      return
    }

    const existingItemRowIndex = cartInMatch.findIndex((item) => item.domesticData.idx === domesticData.idx)
    if (existingItemRowIndex !== -1) {
      const isRePick = cartInMatch[existingItemRowIndex].betId === betId
      if (isRePick) {
        // console.log('repick')
        // If it's the same bet option, remove it
        dispatch(
          removeItemFromCartSport({
            betId,
            domesticIdx: domesticData.idx
          })
        )
        return
      } else {
        // console.log('update')
        const totalRate = cart.pick_list.reduce((rateTotal, curr) => rateTotal * Number(curr.rate), 1)
        const incomingTotalRate = (totalRate / Number(cart.pick_list[existingItemRowIndex].rate)) * Number(rate)
        if (incomingTotalRate > (limitBetting?.BettingLimitsSportQuery.max_payout || 0)) {
          toast.error('최대배당을 초과하였습니다.')
          return
        }
        const typeRuleFlagAlreadyPicked = cartInMatch.some((item) => item.domesticData.game_id === typeRuleFlag)
        if (typeRuleFlagAlreadyPicked) {
          if (categoryUserPick === typeRuleFlag) {
            dispatch(
              updateItemFromCartSport({
                domesticIdx: domesticData.idx,
                betId,
                rate,
                betTeam
              })
            )
            return
          }
          if (!listAllow.includes(categoryUserPick)) {
            return
          }
        } else {
          if (categoryUserPick !== typeRuleFlag) {
            if (isDrawOption && !rules?.is_can_bet_draw && cartInMatch.length === 2) {
              return
            }
            dispatch(
              updateItemFromCartSport({
                domesticIdx: domesticData.idx,
                betId,
                rate,
                betTeam
              })
            )
            return
          } else {
            if (isPickedOptionDraw && !rules?.is_can_bet_draw) {
              // console.log('isPickedOptionDraw')
              return
            }
          }
        }
        dispatch(
          updateItemFromCartSport({
            domesticIdx: domesticData.idx,
            betId,
            rate,
            betTeam
          })
        )
        return
      }
    } else {
      if (listAllow.length === 0) {
        const totalRate = cart.pick_list.reduce((rateTotal, curr) => rateTotal * Number(curr.rate), 1)
        const incomingTotalRate = (totalRate / Number(cartInMatch[0].rate)) * Number(rate)

        if (incomingTotalRate > (limitBetting?.BettingLimitsSportQuery.max_payout || 0)) {
          toast.error('최대배당을 초과하였습니다.')
          return
        }
        dispatch(
          removeAllItemInMatchAndAddNewItem({
            keyMatch: cartInMatch[0].keyMatch,
            dateBet: { domesticData, betId, rate, betTeam, keyMatch }
          })
        )
        return
      }

      // Check if typeRuleFlag is already picked in the cart
      const typeRuleFlagAlreadyPicked = cartInMatch.some((item) => item.domesticData.game_id === typeRuleFlag)
      if (typeRuleFlagAlreadyPicked) {
        const categoriesInCartNotIncludeTypeFlag = cartInMatch
          .map((item) => item.domesticData.game_id)
          .filter((cate) => cate !== typeRuleFlag)
        if (categoriesInCartNotIncludeTypeFlag.some((i) => listAllow.includes(i))) {
          return
        }

        if (!listAllow.includes(categoryUserPick)) {
          return
        }
      } else {
        if (categoryUserPick !== typeRuleFlag) {
          return
        } else {
          const categoriesInCartNotIncludeTypeFlag = cartInMatch
            .map((item) => item.domesticData.game_id)
            .filter((cate) => cate !== typeRuleFlag)
          if (isPickedOptionDraw && !rules?.is_can_bet_draw) {
            return
          }
          if (categoriesInCartNotIncludeTypeFlag.some((i) => !listAllow.includes(i))) {
            return
          }
        }
      }
      const totalRate = cart.pick_list.reduce((rateTotal, curr) => rateTotal * curr.rate, 1)
      const incomingTotalRate = totalRate * Number(rate)

      if (incomingTotalRate > (limitBetting?.BettingLimitsSportQuery.max_payout || 0)) {
        toast.error('최대배당을 초과하였습니다.')
        return
      }
      dispatch(addItemToCartSport({ domesticData, betId, rate, betTeam, keyMatch }))
      return
    }
  }

  const handleSelectBettingFromBoard = ({
    domesticData,
    betId,
    keyMatch,
    rate,
    betTeam
  }: {
    domesticData: DomesticWithKeyMatch
    betId: string
    keyMatch: string
    rate: number
    betTeam: string
  }) => {
    if (getCountItemInMatch(keyMatch) > 1) {
      return
    }
    const cartInMatch = getAllItemInMatch(domesticData)
    const existingBetItem = cartInMatch.find((item) => item.betId === betId)
    if (existingBetItem) {
      dispatch(
        removeItemFromCartSport({
          betId,
          domesticIdx: domesticData.idx
        })
      )
      return
    }
    const totalRate = cart.pick_list.reduce((rateTotal, curr) => rateTotal * Number(curr.rate), 1)
    let incomingTotalRate = totalRate * Number(rate)
    if (cartInMatch.length === 0) {
      incomingTotalRate = totalRate * Number(rate)
    } else {
      incomingTotalRate = (totalRate / Number(cartInMatch[0].rate)) * Number(rate)
    }

    if (incomingTotalRate > (limitBetting?.BettingLimitsSportQuery.max_payout || 0)) {
      toast.error('최대배당을 초과하였습니다.')
      return
    }
    // clear options in same match
    dispatch(
      removeAllItemInMatchAndAddNewItem({
        keyMatch,
        dateBet: {
          domesticData: { ...domesticData, isChosenFromBoard: true },
          betId,
          keyMatch,
          rate,
          betTeam
        }
      })
    )
  }

  const handleSelectOptionFolderBonus = (data: FolderBonusItem, min_rate: number) => {
    // waiting BE change field validate (bet_list_length)
    if (!data.bet_list_length) {
      return
    }
    if (data.bet_list_length > count) {
      toast.warning(`${data.bet_list_length}폴더 이상만 해당 보너스 선택이 가능합니다.`)
      return
    }
    if (data.bet_list_length === cart.folder_bonus?.bet_list_length) {
      return
    }
    const listValidOption = cart.pick_list.filter((item) => Number(item.rate) >= min_rate)
    if (listValidOption.length < data.bet_list_length) {
      toast.warning(`배당이 ${min_rate} 이하인 경우 보너스 선택이 불가합니다.`)
      return
    }

    dispatch(addFolderBonusToCart({ folderBonus: data }))
  }

  const handleGetRateInfoList = async ({
    domesticData,
    sportData,
    isForceOpen = false
  }: {
    domesticData?: Domestic
    sportData: SportItemClient
    isForceOpen?: boolean
  }) => {
    try {
      if (!domesticData) {
        dispatch(resetDataBoardBet())
        return
      }
      // const isSameData =
      //   dataRateInfoList.length > 0 &&
      //   dataRateInfoList[0].fixture_idx === domesticData.fixture_idx &&
      //   dataRateInfoList[0].league_idx === domesticData.league_idx &&
      //   dataRateInfoList[0].sports_idx === domesticData.sports_idx
      //     ? true
      //     : false
      // if (dataRateInfoList.length && isSameData) {
      //   return
      // }
      dispatch(setFetchApiGetAllRateInfo(true))
      dispatch(setEventId(domesticData.fixture_idx.toString()))
      const res = await sportsApi.getSportRateInfoList({
        params: {
          sportsCode: domesticData.sports_idx,
          leagueCode: domesticData.league_idx,
          fixtureCode: domesticData.fixture_idx
        },
        options: {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      })
      if (res.data?.data) {
        const rateInfoList = res.data.data
          // .filter((item) => {
          //   const key = `${item.betid1}|${item.betid2}|${item.betid3}`
          //   return !sportData.domestic.some((domesticItem) => {
          //     const keyDomesticItem = `${domesticItem.betid1}|${domesticItem.betid2}|${domesticItem.betid3}`
          //     return key === keyDomesticItem
          //   })
          // })
          .map((item) => {
            return transformDomesticData(item, sportData)
          })
        dispatch(
          addDataRateInfoList({
            rateInfoList: rateInfoList
          })
        )
      }
    } catch (error) {
      toast.error('문제가 발생했습니다. 나중에 다시 시도 해주십시오.')
      dispatch(setFetchApiGetAllRateInfo(false))
    } finally {
      dispatch(setFetchApiGetAllRateInfo(false))
      isForceOpen && dispatch(setOpenBoardBetSport(true))
      // if (window.innerWidth >= 1024) {
      //   document.getElementById('main-content')?.scrollTo({
      //     top: 0,
      //     left: 0,
      //     behavior: 'smooth'
      //   })
      // }
    }
  }
  return {
    handleSelectBetting,
    handleSelectBettingFromBoard,
    handleGetRateInfoList,
    handleSelectOptionFolderBonus
  }
}
