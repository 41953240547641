import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/libs/utils/store'
import { formatCurrency } from 'src/libs/utils/utilFuncs'
import s from './index.module.scss'
import { getMember } from 'src/libs/apis/graphql/queries/auth.query'
import { login } from 'src/libs/stores/auth.reducer'
import { useEffect, useState } from 'react'
import { Modal } from 'antd'
import { MOVE_MONEY_MUTATION } from 'src/libs/apis/graphql/mutations/money.mutation'
import { toast } from 'react-toastify'
import { GET_PAGE_SITE_QUERY } from 'src/libs/apis/graphql/queries/page.query'
import { SiteInfoQuery } from 'src/libs/apis/graphql/queries/siteInfo.query'
import { Link, useNavigate } from 'react-router-dom'
import path from 'src/constants/path'
import cn from 'classnames'
import Button from '../ui/Button'

export default function UserInfo({ isMobile }: { isMobile?: boolean }) {
  const dispatch = useDispatch()
  const [ring, setRing] = useState(false)
  const [confirmExchangePoint, setConfirmExchangePoint] = useState(false)
  const user = useSelector((state: RootState) => state.auth.user)
  const moneyAnim = useSelector((state: RootState) => state.auth.moneyAnim)

  const { data: siteInfoData } = useQuery(SiteInfoQuery, {
    context: { apiName: 'siteInfo' }
  })
  const { data: PageSiteQuery } = useQuery(GET_PAGE_SITE_QUERY, {
    context: { apiName: 'auth' }
  })
  const navigate = useNavigate()

  const [reloadMember, { loading }] = useLazyQuery(getMember, {
    context: { apiName: 'member' },
    fetchPolicy: 'network-only',
    onCompleted(data) {
      dispatch(login(data?.MemberQuery))
    }
  })

  const [moveMoneyMutation] = useMutation(MOVE_MONEY_MUTATION, {
    context: { apiName: 'member' }
  })

  const handleReloadMember = () => {
    reloadMember()
  }

  useEffect(() => {
    if (user?.notiMoney) {
      setRing(user?.notiMoney)
    }

    setTimeout(() => {
      setRing(false)
    }, 1000)
  }, [user?.mMoney])

  if (isMobile) {
    return (
      <>
        <div className='flex justify-between items-center gap-4 w-full flex-wrap'>
          {user && (
            <>
              <Button
                className={`text-primary rounded-md p-3 items-center text-[15px] font-semibold bg-secondary-7 flex-auto`}
                type='button'
              >
                <div className='flex w-full justify-center items-center gap-2 text-white'>
                  <img src='/assets/images/icons/sportscash.png' className='w-[24px] h-[24px]' alt='' />
                  <span>스포츠캐쉬</span>
                </div>
                <span>{formatCurrency(user?.mSportsMoney)} 원</span>
              </Button>
              <Button
                className={`text-primary rounded-md p-3 items-center text-[15px] font-semibold bg-secondary-7 flex-auto`}
                type='button'
              >
                <div className='flex w-full justify-center items-center gap-2 text-white'>
                  <img src='/assets/images/icons/casinocash.png' className='w-[24px] h-[24px]' alt='' />
                  <span>카지노 캐쉬</span>
                </div>
                <span>{formatCurrency(user?.mMoney)} 원</span>
              </Button>
            </>
          )}
          {/* <div className='text-end bg-secondary-2 flex-auto p-2 rounded'>
            <p className='mb-1'>
              포인트{' '}
              <span onClick={() => setConfirmExchangePoint(true)} className='text-primary cursor-pointer'>
                [전환]
              </span>
            </p>
            <p translate='no'>{formatCurrency(Math.floor(user?.mPoint || 0))} 점</p>
          </div> */}
        </div>
        <Modal
          open={confirmExchangePoint}
          onCancel={() => setConfirmExchangePoint(false)}
          onOk={() => {
            moveMoneyMutation({
              variables: {
                from: 'point',
                to: 'casino_slot',
                amount: Math.floor(user?.mPoint || 0)
              },
              onCompleted(data) {
                reloadMember()
                toast.success(data?.data?.ExchangeMoneyMutation?.message)
              },
              onError(error) {
                toast.error(error.message)
              }
            })

            setConfirmExchangePoint(false)
          }}
        >
          <p className='font-bold text-16 md:text-18'>현재 마일리지를 보유금액으로 전환하시겠습니까?</p>
        </Modal>
      </>
    )
  }

  const OtherButtons = [
    {
      name: '쪽지',
      link: path.note
    },
    {
      name: '고객센터',
      link: path.chat11
    }
  ]

  const rankColor = new Map<string, string>([
    ['1', '!bg-[grey]'],
    ['2', '!bg-[blue]'],
    ['3', '!bg-[green]'],
    ['4', '!bg-pink-500'],
    ['5', '!bg-[yellow]'],
    ['6', '!bg-[orange]'],
    ['7', '!bg-green-800'],
    ['8', '!bg-[red]'],
    ['9', '!bg-[red]']
  ])

  const userLevel = user?.mLevel || '1'
  return (
    <div className={`flex flex-col items-center ${s.UserInfo} p-4`}>
      <div className='flex items-center gap-2 w-full flex-wrap mb-2'>
        <div className='relative top-[-8px]'>
          <img src={`${PageSiteQuery?.PageSiteQuery?.siLogo2 || ''}`} alt='Star icon' className='w-[45px]' />
          <p
            className={cn(
              'absolute bottom-[-13px] left-[calc(50%-10px)] text-12 w-[20px] h-[20px] flex items-center justify-center font-bold bg-purple-600',
              { [rankColor.get(userLevel) || '!bg-default']: true, 'text-black-2': userLevel === '5' }
            )}
          >
            {userLevel}
          </p>
        </div>
        <div className=''>
          <button
            onClick={() => {
              if (!user?.mID) {
                return
              }
              navigate(path.change_password)
            }}
            className='px-2 py-2 flex items-center rounded-t text-white flex-auto text-14'
          >
            <span className='font-bold text-yellow-600 mr-2'>{user?.mID || 'Guest'}</span>
            <span className='text-12'>| {user?.mNick}</span>
          </button>
          <div className='grid grid-cols-2 px-2 mb-2 gap-2 text-left'>
            {OtherButtons.map((item, index) => (
              <Link
                to={item?.link}
                className='text-10 text-center hover:brightness-90 transition-all px-0 py-[1px] border rounded-[5px] border-primary bg-primary text-black'
                key={index}
              >
                {item?.name}
              </Link>
            ))}
          </div>
        </div>
      </div>

      <div className='flex flex-col gap-2 w-full flex-wrap mt-2'>
        <div className='flex gap-2'>
          <p className='mb-1'>스포츠캐쉬:</p>
          <div
            className={`text-end break-all duration-200 ${ring ? 'ring-money relative outline-none border-none z-[10000]' : ''}`}
          >
            <span className={`${moneyAnim ? 'text-red-1 text-[16px]' : ''}`}>
              {formatCurrency(user?.mSportsMoney || 0)}
            </span>
            <span className={`${moneyAnim ? 'text-red-1 text-[16px]' : 'text-white'}`}>원</span>
          </div>
        </div>
        <div className='flex gap-2'>
          <p className='mb-1'>카지노캐쉬:</p>
          <div
            className={`text-end break-all duration-200 ${ring ? 'ring-money relative outline-none border-none z-[10000]' : ''}`}
          >
            <span className={`${moneyAnim ? 'text-red-1 text-[16px]' : ''}`}>{formatCurrency(user?.mMoney || 0)}</span>
            <span className={`${moneyAnim ? 'text-red-1 text-[16px]' : 'text-white'}`}>원</span>
          </div>
        </div>
        {/* <div className='flex gap-2'>
          <p className='mb-1'>
            포인트{' '}
            <span onClick={() => setConfirmExchangePoint(true)} className='text-primary cursor-pointer'>
              [전환]:
            </span>
          </p>
          <p translate='no'>{formatCurrency(Math.floor(user?.mPoint || 0))}P</p>
        </div> */}
        <div className='flex gap-2'>
          <p className='mb-1'>룰렛쿠폰:</p>
          <p translate='no'>{formatCurrency(siteInfoData?.SiteInfoQuery?.roulette_rules?.member_roulette || 0)}</p>
        </div>
      </div>

      <Modal
        open={confirmExchangePoint}
        onCancel={() => setConfirmExchangePoint(false)}
        onOk={() => {
          moveMoneyMutation({
            variables: {
              from: 'point',
              to: 'casino_slot',
              amount: Math.floor(user?.mPoint || 0)
            },
            onCompleted(data) {
              reloadMember()
              toast.success(data?.data?.ExchangeMoneyMutation?.message)
            },
            onError(error) {
              toast.error(error.message)
            }
          })

          setConfirmExchangePoint(false)
        }}
      >
        <p className='font-bold text-16 md:text-18'>현재 마일리지를 보유금액으로 전환하시겠습니까?</p>
      </Modal>
    </div>
  )
}
