/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useMutation, useQuery } from '@apollo/client'
import { Key, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import ReChargeForm from 'src/components/ReChargeForm'
import ReChargeWithDrawInfo from 'src/components/ReChargeWithDrawInfo'
import DataTable from 'src/components/Table/DataTable'
import Button from 'src/components/ui/Button'
import { DELETE_MONEY_INFO_BY_PERIOD_MUTATION } from 'src/libs/apis/graphql/mutations/money.mutation'
import { MoneyInfoListQuery } from 'src/libs/apis/graphql/queries/money.query'
import { InfoQuery } from 'src/libs/apis/graphql/queries/siteInfo.query'
import { setIsLoadingPage } from 'src/libs/stores/common.reducer'
import { showErrorToast } from 'src/libs/utils/error'
import { RootState } from 'src/libs/utils/store'
import { formatCurrency } from 'src/libs/utils/utilFuncs'
import { Wallet } from 'src/types/wallet.type'
import { getRechargeColumns } from './rechargeColumns'
import { InfoQueryResponse, RechargeOption, RechargeOptionClient } from 'src/types/common.type'
import Loading from 'src/components/Loading'

export type DataMoneyType = {
  mProcessDate?: string
  miBankMoney?: number
  miNo?: number
  miRegDate?: string
  miStatus?: string
  miType?: string
  from?: string
  to?: string
  miBonus?: string
  __typename?: string
}
export const MIN_RECHARGE_DEFAULT_VALUE = 10_000
export const MAX_RECHARGE_VALUE = 2_000_000_000
const RECHARGE_TYPE = 'recharge'

export interface GraphQLErrorWithValidations {
  message: string
  validations: Record<string, string[]>
}

export const statusClass = {
  // pending
  대기: 'text-[#2167a3] bg-[#0d1f2d]',
  // approved
  승인완료: 'text-[#11b485] bg-[rgba(17,180,133,.12)]',
  // cancel
  취소처리: 'text-[#dc3644] bg-[rgba(220,54,68,.12)]',
  // rollback
  환수처리: 'text-[#b4ad38] bg-[#292d1f]'
}

const TYPE_OF_PROJECT: 'casino_slot_only' | 'casino_slot_sports' = 'casino_slot_sports'

export default function ReCharge() {
  const [dataRecharge, setDataRecharge] = useState([])
  const [pagination, setPagination] = useState({ current: 1, pageSize: 5, total: 0 })
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([])
  const [rcBonusAllWallet, setRcBonusAllWallet] = useState<{ casino_slot: RechargeOption[]; sports: RechargeOption[] }>(
    {
      casino_slot: [],
      sports: []
    }
  )
  const [listBonus, setListBonus] = useState<RechargeOption[]>([])
  const [bonusSelected, setBonusSelected] = useState<RechargeOption>()
  const [wallet, setWallet] = useState<Wallet>(TYPE_OF_PROJECT === 'casino_slot_only' ? 'casino_slot' : 'sports')
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)
  const sliderRef = useRef<HTMLDivElement>(null)

  const handleMouseDown = (e) => {
    setIsDragging(true)
    if (sliderRef.current) {
      setStartX(e.pageX - sliderRef.current.offsetLeft)
      setScrollLeft(sliderRef.current.scrollLeft)
    }
  }

  const handleMouseLeave = () => {
    setIsDragging(false)
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  const handleMouseMove = (e) => {
    if (!isDragging || !sliderRef.current) return

    e.preventDefault()
    const x = e.pageX - sliderRef.current.offsetLeft
    const walk = (x - startX) * 1.2 // tốc độ kéo
    sliderRef.current.scrollLeft = scrollLeft - walk
  }

  const user = useSelector((state: RootState) => state.auth.user)
  const dispatch = useDispatch()
  const { mBankOwner = '', mBankNumber = 0 } = user || {}

  const { data: dataInfo, loading: loadingDataInfo } = useQuery<InfoQueryResponse>(InfoQuery, {
    context: { apiName: 'siteInfo' },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setRcBonusAllWallet(data.InfoQuery.recharge_config.rc_bonus)
      setListBonus(data.InfoQuery.recharge_config.rc_bonus[wallet])
      setBonusSelected(
        data.InfoQuery.recharge_config.rc_bonus[wallet].length
          ? data.InfoQuery.recharge_config.rc_bonus[wallet][0]
          : undefined
      )
    }
  })

  const chooseWallet = (wallet: Wallet) => {
    setWallet(wallet)
    setListBonus(rcBonusAllWallet[wallet])
    setBonusSelected(rcBonusAllWallet[wallet].length ? data.InfoQuery.recharge_config.rc_bonus[wallet][0] : undefined)
  }

  const { data, refetch, loading } = useQuery(MoneyInfoListQuery, {
    variables: { limit: pagination.pageSize || 1, page: pagination.current || 1, type: RECHARGE_TYPE },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    context: { apiName: 'money-info' }
  })

  const [deleteMoney] = useMutation(DELETE_MONEY_INFO_BY_PERIOD_MUTATION)

  useEffect(() => {
    if (data && data.MoneyInfoListQuery && data.MoneyInfoListQuery?.data) {
      setDataRecharge(
        data.MoneyInfoListQuery.data.map((item: DataMoneyType, index: number) => ({
          ...item,
          key: index
        }))
      )
    }
  }, [data, mBankNumber, mBankOwner, refetch])

  useEffect(() => {
    if (data && data?.MoneyInfoListQuery && data?.MoneyInfoListQuery?.total) {
      setPagination({
        ...pagination,
        total: data.MoneyInfoListQuery.total
      })
    }
  }, [data])

  // useEffect(() => {
  //   if (loading) {
  //     dispatch(setIsLoadingPage(true))
  //   }

  //   return () => {
  //     dispatch(setIsLoadingPage(false))
  //   }
  // }, [dispatch, loading])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  const handleDeleteMoney = (id: Array<any>) => {
    deleteMoney({
      variables: {
        type: RECHARGE_TYPE,
        days: 3
      },
      context: { apiName: 'money-info' },
      onCompleted: (data) => {
        refetch()
        if (!data.DeleteByDays) {
          toast.error('삭제에 실패하였습니다')
          return
        }
        toast.success('입금내역이 삭제되었습니다.')
      },
      onError: (error) => {
        showErrorToast(error)
      }
    })
  }

  const handleSelectMoney = (): DataMoneyType[] => {
    const selected = selectedRowKeys.map((index: number) => dataRecharge[index])

    return selected
  }

  const handleDeleteChoose = () => {
    const data = handleSelectMoney()
    handleDeleteMoney(data)
    setSelectedRowKeys([])
  }

  const renderWalletList = () => {
    if (TYPE_OF_PROJECT === 'casino_slot_only') return

    return (
      <div className='w-full mt-6 border border-[#444444] pt-0 py-3'>
        <div className='px-2 flex w-full gap-2 mt-5 justify-between flex-wrap'>
          <Button
            className={`flex flex-auto justify-between rounded p-3 items-center text-12 border border-primary bg-black ${wallet === 'sports' ? 'bg-primary text-content' : ''}`}
            type='button'
            onClick={() => {
              chooseWallet('sports')
            }}
          >
            <span>스포츠캐쉬</span>
            <span>{formatCurrency(user?.mSportsMoney)} 원</span>
          </Button>
          <Button
            className={`flex flex-auto justify-between rounded p-3 items-center text-12 border border-primary bg-black ${wallet === 'casino_slot' ? 'bg-primary text-content' : ''}`}
            type='button'
            onClick={() => {
              chooseWallet('casino_slot')
            }}
          >
            <span>카지노캐쉬</span>
            <span>{formatCurrency(user?.mMoney)} 원</span>
          </Button>
        </div>
      </div>
    )
  }

  return (
    dataInfo?.InfoQuery && (
      <section className='mt-2 md:p-4' data-aos='fade-up'>
        <div className='p-2.5 border border-[#2f2f2f]'>
          <div className='flex flex-col justify-between gap-[15px]'>
            <ReChargeWithDrawInfo
              title={dataInfo?.InfoQuery.recharge_config.rc_rules || ''}
              titleButton='입금규정 확인하기'
              titleInfo='충전방법 및 주의사항'
            />
            <div className='w-full'>
              {renderWalletList()}
              <div className='w-full mt-6 border border-[#444444]'>
                <div className='pb-5 w-full border-b-[1px] border-[#32230c] mt-6 hidden md:block'>
                  <div className='flex justify-around items-center mt-4'>
                    {listBonus.map((item) => {
                      const srcImg =
                        item.key === 'no_bonus'
                          ? '/assets/images/recharge/no-bonus.png'
                          : item.key === 'bonus'
                            ? '/assets/images/recharge/plus.png'
                            : '/assets/images/recharge/plus.png'
                      return (
                        <button
                          onClick={() => {
                            setBonusSelected(item)
                          }}
                          key={item.key}
                        >
                          <div
                            className={`w-20 h-20 border-2 border-primary hover:bg-primary group flex justify-center items-center ${bonusSelected?.key === item.key ? 'bg-primary' : ''}`}
                          >
                            <img
                              src={srcImg}
                              alt='no bonus'
                              title=' no bonus'
                              width='50'
                              height='50'
                              className={`group-hover:brightness-0 ${bonusSelected?.key === item.key ? 'brightness-0' : ''}`}
                            />
                          </div>
                          <p className='text-12 text-primary mt-2'>{item.title}</p>
                        </button>
                      )
                    })}
                  </div>
                </div>
                <div className='w-full overflow-auto scrollbar-hide md:hidden p-4'>
                  <div className='flex items-center gap-4 overflow-hidden'>
                    <nav
                      ref={sliderRef}
                      onMouseDown={handleMouseDown}
                      onMouseLeave={handleMouseLeave}
                      onMouseUp={handleMouseUp}
                      onMouseMove={handleMouseMove}
                      className='flex items-center gap-2 space-x-4 overflow-x-auto cursor-grab hide-scrollbar'
                    >
                      {listBonus.map((item) => {
                        const srcImg =
                          item.key === 'no_bonus'
                            ? '/assets/images/recharge/no-bonus.png'
                            : item.key === 'bonus'
                              ? '/assets/images/recharge/plus.png'
                              : '/assets/images/recharge/plus.png'
                        return (
                          <button
                            onClick={() => {
                              setBonusSelected(item)
                            }}
                            key={item.key}
                          >
                            <div
                              className={`w-20 h-20 border-2 border-primary hover:bg-primary group flex justify-center items-center ${bonusSelected?.key === item.key ? 'bg-primary' : ''}`}
                            >
                              <img
                                src={srcImg}
                                alt='no bonus'
                                title=' no bonus'
                                width='50'
                                height='50'
                                className={`group-hover:brightness-0 ${bonusSelected?.key === item.key ? 'brightness-0' : ''}`}
                              />
                            </div>
                            <p className='text-12 text-primary mt-2 text-nowrap whitespace-nowrap'>{item.title}</p>
                          </button>
                        )
                      })}
                    </nav>
                  </div>
                </div>
              </div>

              <div className='pt-5 w-full mt-5'>
                <ReChargeForm
                  wallet={wallet}
                  bonusSelected={
                    bonusSelected || {
                      description: '',
                      key: '',
                      title: ''
                    }
                  }
                  dataConfig={dataInfo.InfoQuery}
                />
              </div>
            </div>
          </div>
          <div className='flex justify-between items-center mt-[30px] mb-3'>
            <h2 className='text-white text-18 font-bold'>입금내역</h2>
            <Button
              className='h-8 rounded bg-[#841111] w-fit px-4 min-w-20 font-medium text-14 flex justify-center items-center gap-2'
              type='button'
              onClick={handleDeleteChoose}
            >
              <img src='/assets/images/icons/icon-delete.svg' alt='Delete' title='Delete' width='20' height='25' />
              3일전 내역 삭제
            </Button>
          </div>
          <div className='table-responsive'>
            <DataTable
              columns={getRechargeColumns()}
              loading={loading}
              dataSource={dataRecharge}
              pagination={{
                position: ['bottomCenter'],
                total: pagination.total,
                current: pagination.current,
                pageSize: pagination.pageSize,
                onChange: (page, pageSize) => {
                  if (loading) return
                  setPagination({ ...pagination, current: page, pageSize })
                  setSelectedRowKeys([])
                  refetch()
                }
              }}
              scroll={{
                x: 600
              }}
            />
          </div>
        </div>
      </section>
    )
  )
}
