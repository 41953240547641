import { gql } from '@apollo/client'

export const ROULETTE_MUTATION = gql`
  mutation RouletteMutation {
    rouletteMutation {
      id
      amount
      seconds
    }
  }
`
