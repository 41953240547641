/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useQuery } from '@apollo/client'
import cn from 'classnames'
import { useEffect, useRef, useState } from 'react'
import Draggable from 'react-draggable'
import { IoBanOutline } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import BaseComponent from 'src/components/BaseComponent'
import Collapse from 'src/components/Collapse'
import Header from 'src/components/Header'
import Loading from 'src/components/Loading'
import AuthModal from 'src/components/Modal/AuthModal'
import { topLeftSidebar } from 'src/components/SideBar/SideBar'
import SideBarRight from 'src/components/SideBar/SideBarRight'
import SignOutButton from 'src/components/SignOutButton'
import ToggleCart from 'src/components/ToggleCart.tsx'
import UserInfo from 'src/components/UserInfo'
import path, { pathMiniGame, sportsRouters } from 'src/constants/path'
import { GET_PAGE_SITE_QUERY } from 'src/libs/apis/graphql/queries/page.query'
import { GET_POPUP } from 'src/libs/apis/graphql/queries/popup.query'
import { useViewport } from 'src/libs/hooks/useWindowDimension'
import { setFirstTimeLoginModal } from 'src/libs/stores/auth.reducer'
import { RootState } from 'src/libs/utils/store'
import Login from 'src/pages/Login'
import Register from 'src/pages/Register'
import sideButtonIcon from '../../../public/assets/images/icons/side-button.png'
import SideBarNewSportCategory from '../../components/SideBar/SideBarNewSportCategory.tsx'
import FormAdvisory from 'src/components/Form/FormAdvisory/FormAdvisory.tsx'
import { toast } from 'react-toastify'
import { setCurrentCopyText, setShowCart } from 'src/libs/stores/common.reducer.ts'
interface MainLayoutProps {
  children?: React.ReactNode
}

type Popup = {
  poNo: number
  poLink: string
  poContent: string
  poLoggined: boolean
  poOpenNewWindow: boolean
  showAt?: number
  poMarginLeft: number
  poMarginTop: number
}

export const UserInfoCollapse = () => {
  return (
    <Collapse
      head={<h3>My profile</h3>}
      classHead='flex-row-reverse bg-[#1c1f21] rounded-t-md pl-4 pt-2'
      activeDefault
      classWrapper='p-3 pb-0 pt-6'
      classContent='mx-3 bg-[#1a1c1f]'
      // classRoot='bg-secondary-2'
    >
      <UserInfo />
      <div className='grid grid-cols-5 px-2 mb-4'>
        {topLeftSidebar.map((item, index) => (
          <Link
            to={item?.link}
            className='rounded-md text-12 text-center hover:bg-secondary-1 transition-all py-1'
            key={index}
          >
            <img
              src={item?.icon}
              alt={item?.name}
              title={item?.name}
              width='16'
              height='16'
              className='block mx-auto mb-1'
            />
            {item?.name}
          </Link>
        ))}
        <SignOutButton
          variant='iconOnly'
          className='rounded-md text-12 text-center hover:bg-secondary-1 transition-all py-1 flex justify-center items-center'
        />
      </div>
    </Collapse>
  )
}

export default function MainLayout({ children }: MainLayoutProps) {
  const [showPreparePage, setShowPreparePage] = useState(false)
  // const [showCart, setShowCart] = useState<boolean>(false)
  const [openSidebar, setOpenSidebar] = useState<boolean>(false)
  const [triggerRender, setTriggerRender] = useState(false)
  const [showFirstTimeLoginModal, setShowFirstTimeLoginModal] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sidebarEl = useRef<any>(null)
  const rightSidebarRef = useRef<HTMLDivElement>(null)

  const location = useLocation()
  const dispatch = useDispatch()

  const user = useSelector((state: RootState) => state.auth.user)
  const typeModal = useSelector((state: RootState) => state.auth.authModal.type) as 'login' | 'register' | 'advisory'
  const isOpenAuthModal = useSelector((state: RootState) => state.auth.authModal.isOpen)
  const isLoadingPage = useSelector((state: RootState) => state.common.isLoadingPage)
  const firstTimeLoginModal = useSelector((state: RootState) => state.auth.first_time_login_modal)
  const openCart = useSelector((state: RootState) => state.common.showCart)

  // const cartSport = useSelector((state: RootState) => state.sport.cart)
  const [isShowPopup, setIsShowPopup] = useState(false)

  const hidePopups = JSON.parse(localStorage.getItem('hidePopups') as string) || []
  const token = localStorage.getItem('token')
  const { isIP, isTablet, isLgUp, isLgUpToMax } = useViewport()
  const { data } = useQuery(GET_POPUP, {
    context: {
      apiName: 'popup'
    }
  })

  const [hiddenTmp, setHiddenTmp] = useState<number[]>([])
  const getPopupIdsHiddenIn24Hours = () => {
    return hidePopups.filter((popup: Popup) => popup?.showAt >= Date.now()).map((_) => _.popupNo)
  }

  const isBoscoreMnGameRoute = pathMiniGame.includes(location.pathname)

  useEffect(() => {
    const value =
      !isOpenAuthModal &&
      !showPreparePage &&
      location.pathname === path.home &&
      data?.popupList
        ?.filter((popup: Popup) => !getPopupIdsHiddenIn24Hours().includes(popup?.poNo))
        ?.filter((popup: Popup) => popup?.poLoggined === !!token)
        ?.filter((popup: Popup) => !hiddenTmp.includes(popup.poNo))?.length > 0
    setIsShowPopup(value)
  }, [data, hidePopups, isOpenAuthModal, location.pathname, showPreparePage, hiddenTmp, token])

  useEffect(() => {
    hidePopups?.map((popup: Popup, index) => {
      // handle old version
      if (typeof popup === 'number') {
        hidePopups[index] = {
          poNo: parseInt(popup),
          showAt: Date.now()
        }
      }
    })

    for (let index = 0; index < hidePopups.length; index++) {
      if (hidePopups?.[index]?.showAt < Date.now()) {
        hidePopups.splice(index, 1)
      }
    }

    localStorage.setItem('hidePopups', JSON.stringify(hidePopups))
  }, [])

  const { data: pageSiteQuery } = useQuery(GET_PAGE_SITE_QUERY, {
    context: { apiName: 'auth' }
  })

  const [openRightSidebar, setOpenRightSidebar] = useState(false)

  const toggleRightSidebar = () => {
    if (openSidebar) {
      setOpenSidebar(false)
    }
    setOpenRightSidebar(!openRightSidebar)
  }

  const handleHide = (popupNo: number) => {
    const popupItem = {
      popupNo,
      showAt: Date.now() + 24 * 60 * 60 * 1000
    }

    if (hidePopups.find((popup: Popup) => popup.poNo == popupNo)) return

    hidePopups.push(popupItem)
    localStorage.setItem('hidePopups', JSON.stringify(hidePopups))
    setTriggerRender(!triggerRender)
  }

  useEffect(() => {
    setOpenRightSidebar(false)
    // setOpenSidebar(true)
  }, [location.pathname])

  useEffect(() => {
    if (firstTimeLoginModal) {
      setShowFirstTimeLoginModal(true)
    }
  }, [firstTimeLoginModal])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [openSidebar, openRightSidebar])

  useEffect(() => {
    window.addEventListener('message', (data) => {
      // console.log(data.data === 'OPEN_MAINTENANCE')
    })

    return () => {
      window.removeEventListener('message', () => {})
    }
  }, [])

  if (showPreparePage) return <div></div>

  const handleClickOutside = (event: MouseEvent) => {
    // check if event.target is node child of left-sidebar id and right-sidebar id then return. otherwise close sidebar
    if (sidebarEl.current && sidebarEl.current.contains(event.target as Node)) return
    if (rightSidebarRef.current && rightSidebarRef.current.contains(event.target as Node)) return

    if (!(event.target as Element)?.closest('.left-menu-icon')) {
      setOpenSidebar(false)
    }

    if (!(event.target as Element)?.closest('.right-menu-icon')) {
      setOpenRightSidebar(false)
    }
  }

  const toggleLeftSidebar = () => {
    if (openRightSidebar) {
      setOpenRightSidebar(false)
    }
    setOpenSidebar(!openSidebar)
  }

  const heightHeader = document.getElementsByTagName('header')[0]?.clientHeight

  useEffect(() => {
    const spanElements = document.querySelectorAll<HTMLSpanElement>('.event-copy')
    spanElements.forEach((element) => {
      const handleClick = (event: MouseEvent) => {
        event.preventDefault()

        const parentElement = element.closest('.copy-item')
        if (parentElement) {
          let textToCopy = parentElement.textContent?.trim() || ''
          const iconElement = element
          if (iconElement) {
            textToCopy = textToCopy.replace(iconElement.textContent?.trim() || '', '').trim()
          }

          dispatch(setCurrentCopyText(textToCopy))
        }
      }

      element.addEventListener('click', handleClick)

      return () => {
        element.removeEventListener('click', handleClick)
      }
    })
  })

  useEffect(() => {
    setOpenSidebar(false)
  }, [location.pathname])

  return (
    <main>
      {isLoadingPage && <Loading />}
      <ToggleCart />

      <BaseComponent />
      <Header toggleLeftSidebar={toggleLeftSidebar} toggleRightSidebar={toggleRightSidebar} />
      {/* <NavBar /> */}
      <div
        className={cn(
          `lg:flex ${isIP ? 'max-h-[calc(100dvh-146px)]' : 'max-h-[calc(100dvh-110px)]'} overflow-hidden scroll-data bg-[#0d0d0d]`,
          {
            '!max-h-[calc(100dvh-0px)]': isLgUpToMax && sportsRouters.includes(location.pathname)
          }
        )}
      >
        <div></div>
        <div
          className={`relative lg:block 3xl:w-[320px] shrink-0 sidebar__overflow xl:overflow-y-auto ${openSidebar ? 'block' : 'lg:w-[50px]'}`}
          // className={`relative lg:block 3xl:w-[320px] shrink-0 sidebar__overflow xl:overflow-y-auto ${openSidebar ? 'block' : 'lg:w-[50px]'} ${isBoscoreMnGameRoute && openSidebar ? 'xl:!w-[300px]' : isBoscoreMnGameRoute ? 'xl:!w-[50px] MiniGameBoscore:!w-[300px]' : ''}`}
          ref={sidebarEl}
        >
          <div
            className={`lg:w-full absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] cursor-pointer lg:block z-[11] ${openSidebar ? '!hidden' : ''}`}
            id='sidebar'
            onClick={() => setOpenSidebar(!openSidebar)}
          >
            <img
              src={sideButtonIcon}
              alt=''
              // className='hidden lg:block xl:hidden mx-auto'
              className={`hidden grayscale lg:block xl:block 2xl:block 3xl:hidden mx-auto ${isBoscoreMnGameRoute ? 'xl:!block MiniGameBoscore:!hidden' : ''}`}
            />
          </div>

          {/*SideBar old*/}
          {/*<SideBar open={openSidebar} isMiniGameRoute={isBoscoreMnGameRoute} />*/}

          {/*SideBarLisSportCategoryNew*/}
          <SideBarNewSportCategory
            open={openSidebar}
            isMiniGameRoute={isBoscoreMnGameRoute}
            toggleRightSidebar={toggleRightSidebar}
          />
        </div>
        <div
          className={cn(
            `pb-[40px] flex-auto min-w-0 min-h-0 pn-8 overflow-y-auto sidebar__overflow ${isIP ? 'max-h-[calc(100dvh-146px)]' : 'max-h-[calc(100dvh-110px)]'}`,
            {
              '!max-h-[calc(100dvh-0px)]': isLgUpToMax && sportsRouters.includes(location.pathname)
            },
            {
              '!overflow-hidden': location.pathname === path.sports
            }
          )}
          id='main-content'
        >
          {children}
        </div>
        <div
          // style={{ top: heightHeader }}
          ref={rightSidebarRef}
          className={`hidden xl:block max-w-[350px] xl:max-w-[320px] overflow-y-auto sidebar__overflow fixed lg:relative duration-200 lg:!top-0 ${openCart ? '!block right-0 max-h-dvh bg-secondary-2 max-w-full !top-0' : ''} ${openRightSidebar ? `!top-0 !block right-0 max-h-dvh bg-secondary-2 max-w-full` : 'lg:opacity-100 -right-full lg:right-0'} flex-shrink-0`}
          style={{ top: heightHeader }}
        >
          <button
            className='w-full flex items-end justify-end '
            onClick={() => {
              setOpenRightSidebar(false)
              dispatch(setShowCart(false))
            }}
          >
            <img className='lg:hidden w-[15px] h-[15px] mx-5 mt-4' src='/Close.png' alt='' />
          </button>

          {!!user && <UserInfoCollapse />}
          <SideBarRight />
        </div>
      </div>
      {/* <NavBar /> */}

      {/*<Auth Popup />*/}
      <AuthModal typeModal={typeModal}>
        {typeModal === 'login' ? <Login /> : typeModal === 'advisory' ? <FormAdvisory /> : <Register />}
      </AuthModal>
      {/*<Auth Popup />*/}

      {isShowPopup && !showFirstTimeLoginModal ? (
        <div
          className='fixed inset-0 overflow-hidden bg-black bg-opacity-50 z-50 flex flex-wrap justify-center items-start pt-10 gap-1'
          id='popup-notices'
        >
          {data?.popupList
            ?.filter((popup: Popup) => !getPopupIdsHiddenIn24Hours().includes(popup?.poNo))
            ?.filter((popup: Popup) => popup?.poLoggined === !!token)
            ?.filter((popup: Popup) => !hiddenTmp.includes(popup.poNo))
            .splice(0, isIP ? 1 : isTablet ? 2 : 3)
            .map((popup: Popup, index: number) => {
              return (
                <Draggable key={popup.poNo} bounds='parent'>
                  <div
                    className='text-white bg-black/50 flex flex-col-reverse shadow-lg cursor-move rounded-md overflow-hidden w-[375px] h-[570px]'
                    style={{
                      zIndex: 1000 + index
                    }}
                    id={`popup-${popup?.poNo}`}
                  >
                    <div className='header flex items-center justify-end p-1 pb-2 bg-white text-content rounded-b-sm'>
                      <div className='flex'>
                        <label
                          className='text-12 pl-2 text-black-1'
                          onClick={() => handleHide(popup.poNo)}
                          onTouchStart={() => handleHide(popup.poNo)}
                        >
                          <input type='checkbox' className='mr-1' />
                          오늘 이창을 다시 열지 않기
                        </label>
                      </div>
                      <div>
                        <button
                          className='z-[9999]'
                          onClick={() => handleHide(popup.poNo)}
                          onTouchStart={() => handleHide(popup.poNo)}
                        >
                          <img src='/assets/images/icons/close-auth-modal.svg' alt='Close' className='w-5 h-5' />
                        </button>
                      </div>
                      <button
                        className='border border-[#9c9c9c] py-[3px] px-[8px] rounded-sm text-12 bg-[#dadada] text-black-1'
                        onClick={() => setHiddenTmp([...hiddenTmp, popup.poNo])}
                        onTouchStart={() => setHiddenTmp([...hiddenTmp, popup.poNo])}
                      >
                        닫기
                      </button>
                    </div>
                    <div className='border-4 border-white overflow-hidden w-full h-full rounded-t-sm'>
                      <div
                        dangerouslySetInnerHTML={{ __html: popup.poContent }}
                        className='w-full h-full object-contain relative z-[-1]'
                      />
                    </div>
                  </div>
                </Draggable>
              )
            })}
        </div>
      ) : null}

      {showFirstTimeLoginModal && (
        <div className='popup-notices w-full h-screen absolute top-0 left-0 flex items-center justify-evenly gap-3 p-4'>
          <div className={`text-white bg-[#2b3139] w-[400px] pb-[16px]`}>
            <div className='header flex px-[20px] py-2 gap-2'>
              <img src='/assets/images/icons/noti-popup.svg' className='w-[18px] h-[18px]' alt='' />
              <p className='flex-auto'>공지사항</p>
            </div>
            <div
              className='block content max-h-[550px] overflow-y-auto p-4 border-t-2 border-primary-2'
              dangerouslySetInnerHTML={{ __html: firstTimeLoginModal ?? '' }}
            />
            <div className='flex justify-center mt-[16px] border-t-2 border-primary-2'>
              <button
                className=' bg-primary text-content py-[5px] px-[20px] rounded-sm mt-[16px]'
                onClick={() => {
                  dispatch(setFirstTimeLoginModal(''))
                  setShowFirstTimeLoginModal(false)
                }}
              >
                창닫기
              </button>
            </div>
          </div>
        </div>
      )}
    </main>
  )
}
