import { CheckCircleOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { Modal, Spin } from 'antd'
import classNames from 'classnames'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { USE_COUPON_MUTATION } from 'src/libs/apis/graphql/mutations/coupon'
import { GET_COUPON_LIST } from 'src/libs/apis/graphql/queries/coupon.query'
import { showErrorToast } from 'src/libs/utils/error'
import { SpinWheelModal } from '../SpinWheel'
import ModalRoulette from './ModalRoulette'
import useSubscribeCouponChannel from './useSubscribeCouponChannel'
import { formatCurrency } from 'src/libs/utils/utilFuncs'

export enum CouponType {
  MONEY_BONUS = 'money_bonus',
  ROULETTE_BONUS = 'roulette_bonus',
  RECHARGE_BONUS = 'recharge_bonus'
}

export type Coupon = {
  id: string
  name: string
  startDate: string
  endDate: string
  amount: number
  // 0: available, 1: used, 2: expired, 4: still available
  status: 0 | 1 | 2 | 4
  type: CouponType.MONEY_BONUS | CouponType.ROULETTE_BONUS | CouponType.RECHARGE_BONUS
}

const validCouponTypes = [CouponType.MONEY_BONUS, CouponType.ROULETTE_BONUS, CouponType.RECHARGE_BONUS]

type CouponItemProps = {
  coupon: Coupon
  handleUseCoupon: (coupon: Coupon) => void
}

const CouponItem = (props: CouponItemProps) => {
  const { coupon, handleUseCoupon } = props
  const { name, startDate, endDate, status, type, amount = 0 } = coupon

  const isExpired = status === 2
  const isUsed = status === 1
  const isAvailable = status === 0 || 4
  const couponType = type

  const handleClickCoupon = () => {
    if (isExpired || isUsed) return
    handleUseCoupon(coupon)
  }

  if (!isAvailable) return

  return (
    <div className={classNames('coupon-item', { 'opacity-20': isExpired || isUsed })}>
      <div className='grid grid-cols-10 gap-2 h-full'>
        <div className='coupon-info rounded-md border border-primary px-4 pb-8 pt-4 col-span-8'>
          <h3 className='text-primary text-22 my-5'>{name}</h3>
          <h4 className='text-primary text-20 mt-1 mb-4'>
            {couponType === CouponType.MONEY_BONUS
              ? formatCurrency(amount || 0) + ' 원'
              : couponType === CouponType.ROULETTE_BONUS
                ? amount + ' 개'
                : null}
          </h4>
          <div className='coupon-date text-14'>
            <div>
              <p>유효기간: {startDate}</p>
            </div>
            <div>
              <p>만료날짜: {endDate}</p>
            </div>
          </div>
        </div>
        <button
          className={classNames(
            'use-coupon-btn border border-primary px-2 py-4 flex flex-col items-center gap-3 justify-center rounded-md col-span-2',
            {
              'cursor-default': !isAvailable
            }
          )}
          onClick={isAvailable ? handleClickCoupon : undefined}
        >
          <CheckCircleOutlined className='text-24 my-5' />
          <span className='text-14'>사용하기</span>
        </button>
      </div>
    </div>
  )
}
const CouponList = () => {
  const [openModalConfirm, setOpenModalConfirm] = useState(false)
  const [openRoulette, setOpenRoulette] = useState(false)
  const [selectedCoupon, setSelectedCoupon] = useState<Coupon | null>(null)
  const [numberRoulette, setNumberRoulette] = useState<number>()
  const [dataCoupon, setDataCoupon] = useState<Coupon | undefined>(undefined)
  useSubscribeCouponChannel()

  const {
    data,
    loading,
    refetch: refetchCouponList
  } = useQuery(GET_COUPON_LIST, {
    context: {
      apiName: 'member'
    }
  })

  const [redeemCoupon] = useMutation(USE_COUPON_MUTATION, {
    context: {
      apiName: 'member'
    },
    refetchQueries: [GET_COUPON_LIST]
  })

  const isRouletteCoupon = (coupon: Coupon) => {
    return coupon.type === 'roulette_bonus'
  }

  const handleOpenRouletteModal = (id: string) => {
    if (id) {
      setOpenRoulette(true)
    }
  }

  const handleUseCoupon = (coupon: Coupon) => {
    const isNotAllowTypes = !validCouponTypes.includes(coupon?.type)
    if (isNotAllowTypes) {
      toast.error('SERVER ERROR')
      return
    }

    if (isRouletteCoupon(coupon)) {
      redeemCoupon({
        variables: {
          couponId: coupon.id
        },
        onCompleted: (res) => {
          const rouletteNumber = res.RedeemMutation
          if (rouletteNumber) {
            setNumberRoulette(rouletteNumber)
            setDataCoupon(coupon)
            handleOpenRouletteModal(coupon.id)
            return
          }
          toast.error('쿠폰 사용에 실패하였습니다.')
        },
        onError: (error) => {
          showErrorToast(error)
        }
      })

      return
    }

    setSelectedCoupon(coupon)
    setOpenModalConfirm(true)
  }

  const handleConfirmUseCoupon = () => {
    if (!selectedCoupon?.id) return
    redeemCoupon({
      variables: {
        couponId: selectedCoupon.id
      },
      onCompleted: (res) => {
        const rouletteNumber = res.RedeemMutation
        if (rouletteNumber) {
          toast.success('쿠폰 사용에 성공하였습니다.')
          return
        }

        toast.error('쿠폰 사용에 실패하였습니다.')
      },
      onError: (error) => {
        showErrorToast(error)
      }
    })
    setOpenModalConfirm(false)
  }

  const handleCancelUseCoupon = () => {
    console.log('사용하기 취소')
    setOpenModalConfirm(false)
  }

  const handleCloseRouletteModal = () => {
    setOpenRoulette(false)
  }

  // const rouletteModalStyle = {
  //   body: 'bg-black',
  //   content: 'bg-black !p-0 text-white',
  //   footer: '!bg-black !p-0 !mt-0'
  // }

  return (
    <div className='coupon-list grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 px-2 gap-4'>
      {loading ? (
        <div className='flex justify-center items-center h-full'>
          <Spin />
        </div>
      ) : (
        data?.CouponQuery?.map((coupon: Coupon) => (
          <CouponItem key={coupon.id} coupon={coupon} handleUseCoupon={handleUseCoupon} />
        ))
      )}

      <Modal
        open={openModalConfirm}
        onCancel={handleCancelUseCoupon}
        onOk={handleConfirmUseCoupon}
        destroyOnClose
        centered
      >
        <div className='text-18 font-bold'>쿠폰을 사용하시겠습니까?</div>
      </Modal>

      <ModalRoulette open={openRoulette} onCancel={handleCloseRouletteModal}>
        <SpinWheelModal
          onCancelModal={handleCloseRouletteModal}
          refetchCouponList={refetchCouponList}
          coupon={dataCoupon}
          numberRoulette={numberRoulette}
          setNumberRoulette={setNumberRoulette}
        />
      </ModalRoulette>
    </div>
  )
}

export default CouponList
