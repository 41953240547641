import InfiniteScroll from 'react-infinite-scroll-component'
import Collapse from 'src/components/Collapse'
import { FolderBonusQueryRes, GroupSportListByKey } from 'src/types/sports.type'
import { FoldersBonus } from './FoldersBonus'
import { GroupSportList } from './GroupSportList'
type Props = {
  listGroupSportByKeys: GroupSportListByKey[]
  folderBonusQuery?: FolderBonusQueryRes
  isLoadingFolderBonus: boolean
}

export const GroupSportByTime = ({ listGroupSportByKeys, folderBonusQuery, isLoadingFolderBonus }: Props) => {
  return (
    <div className='3xl:w-full flex flex-col gap-4'>
      <FoldersBonus isLoadingFolderBonus={isLoadingFolderBonus} folderBonusQuery={folderBonusQuery} />
      <div className='flex items-center gap-1 px-3 py-1 bg-secondary-2'>
        <span className='text-[24px] text-primary'>※</span>
        <span className='text-10 text-[#707478]'>
          실시간에서 스페셜을 모두 아우르는 최고의 배팅마켓을 제공합니다.
          <br />
          실시간 배팅규정은
          <span className='text-primary cursor-pointer'>“실시간 규정확인”</span> 을 열람하시면 확인 가능합니다.
        </span>
      </div>
      <InfiniteScroll
        className='overflow-hidden space-y-2 3xl:w-full'
        dataLength={listGroupSportByKeys.length || 0}
        next={() => {}}
        hasMore={false}
        scrollableTarget={'main-content-sports'}
        loader={
          <div className='flex justify-center items-center w-full py-4 animate-pulse'>
            <img src='/logo.gif' alt='logo' />
          </div>
        }
      >
        {listGroupSportByKeys.length
          ? listGroupSportByKeys.map((listGroupSportByKeyItem) => {
              if (!listGroupSportByKeyItem.listGroupSport.length) {
                console.log('?', listGroupSportByKeyItem)
                return
              }
              return (
                <Collapse
                  key={listGroupSportByKeyItem.key}
                  classRoot='bg-black-4 rounded-md'
                  activeDefault={true}
                  head={
                    <div className='flex justify-between items-center w-full gap-2'>
                      <div className='flex items-center gap-1.5 2xl:gap-2 text-12'>
                        <img
                          src={listGroupSportByKeyItem.listGroupSport[0].logo_sports}
                          alt='icon'
                          title='icon'
                          className='w-[18px]'
                        />
                        <img src='/assets/images/sports/arrows_1.svg' alt='icon' title='icon' width='10' height='11' />
                        <img
                          src={listGroupSportByKeyItem.listGroupSport[0].logo_location}
                          alt='icon'
                          title='icon'
                          width='18'
                        />
                        <p>{listGroupSportByKeyItem.listGroupSport[0].location_name}</p>
                        <img src='/assets/images/sports/arrows_1.svg' alt='icon' title='icon' width='10' height='11' />
                        <p>{listGroupSportByKeyItem.listGroupSport[0].league_name || ''}</p>
                      </div>
                    </div>
                  }
                >
                  <GroupSportList key={listGroupSportByKeyItem.key} listGroupSportByKey={listGroupSportByKeyItem} />
                </Collapse>
              )
            })
          : null}
      </InfiniteScroll>
    </div>
  )
}
