import { useMutation, useQuery } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { ChangeEvent } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { MEMBER_AUTO_CREATE_MUTATION } from 'src/libs/apis/graphql/mutations/consultation.mutation'
import { RechargeMutation } from 'src/libs/apis/graphql/mutations/money.mutation'
import { getConsultation } from 'src/libs/apis/graphql/queries/consultation.queries'
import { showErrorToast } from 'src/libs/utils/error'
import { reChargeSchema } from 'src/libs/utils/rules'
import { RootState } from 'src/libs/utils/store'
import { formatCurrency, reFormatCurrency } from 'src/libs/utils/utilFuncs'
import { InfoQueryType, RechargeOption, SiteInfoType } from 'src/types/common.type'
import { Wallet } from 'src/types/wallet.type'
import Input from '../Input'
import Button from '../ui/Button'
import { dataButton } from './dataButton'

type ReChargeForm = {
  price: string
  bonus?: string
}

type ReChargeFormProps = {
  bonusSelected: RechargeOption
  dataConfig: InfoQueryType
  wallet: Wallet
}

export default function ReChargeForm({ dataConfig, bonusSelected, wallet }: ReChargeFormProps) {
  const { description: rechargeConfig, key: bonus } = bonusSelected
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<ReChargeForm>({
    mode: 'onBlur',
    resolver: yupResolver(reChargeSchema(dataConfig))
  })
  const user = useSelector((state: RootState) => state.auth.user)
  const config = useSelector((state: RootState) => state.common.pageSiteQuery)

  const [recharge] = useMutation(RechargeMutation, {
    onError: (error) => {
      showErrorToast(error)
    },
    onCompleted: (data) => {
      if (data?.RechargeMutation?.miType === 'UD') {
        toast.success('입금신청되었습니다')
        setValue('price', '')
        setError('price', {})
      }
    },
    context: { apiName: 'money-info' }
  })

  const { data: dataConsultation, refetch: refetchConsultation } = useQuery(getConsultation, {
    variables: { limit: 10, page: 1 },
    context: { apiName: 'consultation' }
  })

  const [reqConsultation] = useMutation(MEMBER_AUTO_CREATE_MUTATION, {
    onCompleted: (data) => {
      if (data?.memberAutoCreateMutation?.status) {
        toast.success('문의되었습니다')
        refetchConsultation()
      }
    },
    onError: (error) => {
      showErrorToast(error)
    },
    context: { apiName: 'consultation' }
  })

  const handleQuickReqChat = async () => {
    // if (dataConsultation?.checkRequireReplyQuery?.status) return

    await reqConsultation({
      variables: { title: '입금계좌문의', content: '입금계좌문의' }
    })
  }

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const numericValue = value?.replace(/\D/g, '')
    event.target.value = formatCurrency(numericValue)
  }

  const handleButtonClick = (value: number) => {
    const currentValue = watch('price') || '0'
    const newValue = parseInt(currentValue?.replace(/[^\d]/g, '')) || 0
    setValue('price', formatCurrency((newValue + value).toString()))
  }

  const onSubmit = handleSubmit(async (formData: ReChargeForm) => {
    await recharge({
      variables: {
        amount: reFormatCurrency(formData.price),
        wallet,
        bonus
      }
    })
  })

  return (
    <form noValidate onSubmit={onSubmit}>
      <div className='border border-gray-2'>
        <div className='border-b border-gray-2'>
          <div className='flex items-center'>
            <p className=' min-w-[100px] md:min-w-[130px] h-[60px] flex justify-center items-center border-r border-gray-2'>
              입금하실금액
            </p>
            {config?.enable_consultation && !dataConsultation?.checkRequireReplyQuery?.status ? (
              <Button
                className='rounded text-12 h-[38px] bg-black border border-primary px-4 w-max ml-1 md:ml-3 pt-[5px]'
                type='button'
                onClick={handleQuickReqChat}
              >
                <span>빠른계좌문의</span>
              </Button>
            ) : (
              <div className='rounded text-12 h-[38px] bg-black border border-primary px-4 w-max ml-1 md:ml-3 pt-[5px]'>
                <span className='text-14'>빠른계좌문의</span>
              </div>
            )}
          </div>
        </div>
        <div className='border-b border-gray-2 '>
          <div className='flex items-center'>
            <p className='min-w-[100px] md:min-w-[130px] h-[60px] flex justify-center items-center  border-r border-gray-2'>
              보너스
            </p>
            <div>
              <p className='text-[15px] text-white ml-1 md:ml-3'>{rechargeConfig}</p>
            </div>
          </div>
        </div>
        <div className='border-b border-gray-2 '>
          <div className='flex'>
            <p className='min-w-[100px] md:min-w-[130px] flex justify-center items-center  border-r border-gray-2'>
              충전금액
            </p>
            <div className='flex flex-wrap gap-2 ml-1 md:ml-3 pb-2 py-2'>
              <Input
                name='price'
                type='text'
                register={register}
                placeholder='입금(충전) 금액'
                errorMessage={errors?.price?.message}
                required
                disabled={dataConfig.recharge_config.rc_manual_recharge}
                classNameInput='placeholder:text-[#757575] text-dark text-14 h-10 p-3 rounded border border-primary max-w-[190px] bg-black !placeholder:!text-[#766a57]'
                classNameError='text-red-1 mt-1 text-12'
                className='pr-1 pt-2 md:pt-0'
                onChange={(e) => onInputChange(e)}
              />
              <div className='flex flex-wrap gap-1 md:gap-0'>
                {dataButton?.map((item, index) => (
                  <Button
                    className='h-10 bg-black border border-primary text-white md:w-fit px-4 w-[calc(50%-4px)] md:min-w-20 font-medium text-14'
                    type='button'
                    key={index}
                    onClick={() => handleButtonClick(item.value)}
                  >
                    {item?.name}
                  </Button>
                ))}
                <Button
                  className='h-10 bg-black border border-primary text-white md:w-fit px-4 w-[calc(50%-4px)] md:min-w-20 font-medium text-14'
                  type='button'
                  onClick={() => setValue('price', '')}
                >
                  정정하기
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className='border-b border-gray-2 '>
          <div className='flex items-center'>
            <p className='min-w-[100px] md:min-w-[130px] h-[60px] flex justify-center items-center  border-r border-gray-2'>
              입금자명
            </p>
            <div className='underline underline-offset-4 text-primary text-14 font-black h-10 px-3 rounded w-[190px] flex items-center min-w-[125px] ml-3'>
              {user?.mBankOwner}
            </div>
          </div>
        </div>
      </div>
      <div className='flex justify-center items-center'>
        <Button
          className='rounded h-11 bg-primary text-white w-fit px-4 min-w-[90px] font-medium text-14 mt-3'
          type='submit'
        >
          입금신청
        </Button>
      </div>
    </form>
  )
}
