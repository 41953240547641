import React from 'react'

type Props = {
  size: number
  color?: string
  className?: string
}

export const ArrowBottomIcon = (props: Props) => {
  const { size, color, className } = props
  return (
    <svg
      width={size + 5 + 'px'}
      height={size + 'px'}
      viewBox={`0 0 ${size} ${size}`}
      fill={color || 'white'}
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M13.1484 2.8457L7.35156 8.63477C7.2526 8.73372 7.13542 8.7832 7 8.7832C6.86458 8.7832 6.7474 8.73372 6.64844 8.63477L0.851562 2.8457C0.752604 2.74674 0.703125 2.62826 0.703125 2.49023C0.703125 2.35221 0.752604 2.23372 0.851562 2.13477L2.14844 0.845703C2.2474 0.746745 2.36458 0.697266 2.5 0.697266C2.63542 0.697266 2.7526 0.746745 2.85156 0.845703L7 4.99414L11.1484 0.845703C11.2474 0.746745 11.3646 0.697266 11.5 0.697266C11.6354 0.697266 11.7526 0.746745 11.8516 0.845703L13.1484 2.13477C13.2474 2.23372 13.2969 2.35221 13.2969 2.49023C13.2969 2.62826 13.2474 2.74674 13.1484 2.8457Z'
        fill={color || 'white'}
      />
    </svg>
  )
}
