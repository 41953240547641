import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { config } from 'src/constants/config'
import { useCartSportStore } from 'src/libs/hooks/store/useCartSportStore'
import { useSportSearchStore } from 'src/libs/hooks/store/useSportSearchStore'
import {
  lockDataSearchPure,
  updateAllStatusDefault,
  updateAllStatusDefaultRateInfo,
  updateDataFromSearchList,
  updateDataRateInfoList
} from 'src/libs/stores/sport-search.reducer'
import { updateAndLockItem } from 'src/libs/stores/sport.reducer'
import { checkIsCanBettingRate } from 'src/libs/utils/sports.func'
import { usePusher } from 'src/providers/PusherProvider'
import { BetItemPusher, UpdateRecordParent } from 'src/types/sports.type'

const SPORTS_CHANNEL = 'sports-channel'
const SPORTS_UPDATE_EVENT = 'sports-update-event'
const SPORTS_CREATE_MARKET_EVENT = 'sports-create-market-event'
const SPORTS_FIXTURE_BLOCK_EVENT = 'fixture-block-betting-event'

const randomElement = (list: string[]): string => {
  const randomIndex = Math.floor(Math.random() * list.length)
  return list[randomIndex]
}

const calculateBetRate = ({
  rate,
  percent_distribution = 0,
  rate_deduct = 0
}: {
  rate: number
  percent_distribution?: number
  rate_deduct?: number
}) => {
  const newRate = rate + rate * ((percent_distribution + rate_deduct) / 100)
  return Number(newRate.toFixed(2))
}

// const getSportsCodeFromLocation = (): number | null => {
//   const searchParams = new URLSearchParams(window.location.search)
//   return Number(searchParams.get('sportsCode'))
// }
export const useEventSports = () => {
  const pusher = usePusher()
  const dispatch = useDispatch()
  const [isOpenModalCartChange, setIsOpenModalCartChange] = useState(false)
  const { dataSportSearchPure, dataRateInfoList, isOpenBoardBetSport, objExchangeRateInfo } = useSportSearchStore()
  const fixtureIdxList = useMemo(() => {
    return dataSportSearchPure.map((item) => item.idx)
  }, [dataSportSearchPure])
  const { cart, limitBetting } = useCartSportStore()
  // const sportsCode = getSportsCodeFromLocation()
  // console.log(
  //   dataRateInfoList
  //     .map((item) => {
  //       if (item.game_id === '220') {
  //         return item
  //       }
  //       return null
  //     })
  //     .filter((item) => item !== null)
  //     .map((item) => `${item.betid1}|${item.betid3}`.split('|'))
  //     .flat()
  //     .filter((item) => item.trim() !== '')
  // )
  useEffect(() => {
    const channel = pusher?.subscribe(SPORTS_CHANNEL)
    // let timeoutData: NodeJS.Timeout
    // let timeoutRareInfo: NodeJS.Timeout
    // const clearTimeouts = () => {
    //   if (timeoutData) clearTimeout(timeoutData)
    //   if (timeoutRareInfo) clearTimeout(timeoutRareInfo)
    // }
    channel?.bind(SPORTS_UPDATE_EVENT, async ({ updateRecordParent }: { updateRecordParent: UpdateRecordParent }) => {
      if (!dataSportSearchPure.length) {
        return
      }
      if (!fixtureIdxList.includes(updateRecordParent.fixture_idx)) {
        return
      }
      // const isLock = Math.random() > 0.5 ? 2 : 1
      // const fakeUpdate: UpdateRecordParent = {
      //   ...updateRecordParent,
      //   fixture_idx: 13668421,
      //   bets: [
      //     {
      //       ...updateRecordParent.bets[0],
      //       betid: randomElement([
      //         '103512411513668421',
      //         '193689347513668421',
      //         '103512418913668421',
      //         '186732917513668421',
      //         '39644536713668421',
      //         '59943231313668421',
      //         '103512422213668421',
      //         '88175715613668421',
      //         '39644540013668421',
      //         '214531327813668421',
      //         '55116280213668421',
      //         '193689357213668421',
      //         '103512437913668421',
      //         '122877381313668421',
      //         '103512428813668421',
      //         '59943215413668421',
      //         '39644530913668421',
      //         '180263767913668421',
      //         '103512434613668421',
      //         '180263752013668421',
      //         '39644543313668421',
      //         '88175731513668421',
      //         '39644534213668421',
      //         '94644865213668421',
      //         '103512441213668421',
      //         '152031251813668421',
      //         '55116276913668421',
      //         '39101260713668421'
      //       ]),
      //       status: 1
      //     }
      //   ]
      // }
      // updateRecordParent = { ...fakeUpdate }
      const existingIndex = dataSportSearchPure.findIndex((item) => item.idx === updateRecordParent.fixture_idx)
      // const existingIndex = dataSportSearchPure.findIndex((item) => item.idx === 13668421)
      if (existingIndex !== -1) {
        const updateRecordParentCalculateBetRate = { ...updateRecordParent }
        updateRecordParentCalculateBetRate.bets.forEach((bet) => {
          const existingIndexInDataListPure = dataSportSearchPure.findIndex((item) => {
            const listBetIdDomestic = item?.domestic
              .map((domesticItem) => `${domesticItem.betid1}|${domesticItem.betid2}|${domesticItem.betid3}`.split('|'))
              .flat()
            return listBetIdDomestic.includes(bet.betid)
          })
          if (existingIndexInDataListPure !== -1) {
            const updateBetItem: BetItemPusher = {
              ...bet,
              rate: calculateBetRate({
                rate: bet.rate,
                percent_distribution: limitBetting?.BettingLimitsSportQuery.percent_distribution,
                rate_deduct: objExchangeRateInfo[updateRecordParentCalculateBetRate.sports_idx]
              })
            }
            dispatch(
              updateDataFromSearchList({
                betItemPusher: updateBetItem,
                fixture_idx: updateRecordParentCalculateBetRate.fixture_idx,
                index: existingIndex
              })
            )
          }
          // clearTimeouts()
          // timeoutData = setTimeout(() => {
          //   dispatch(updateAllStatusDefault())
          // }, 3000)
        })
      }

      if (
        isOpenBoardBetSport &&
        dataRateInfoList.length &&
        updateRecordParent.fixture_idx === dataRateInfoList[0].fixture_idx
      ) {
        const updateRecordParentCalculateBetRate = { ...updateRecordParent }
        updateRecordParentCalculateBetRate.bets.forEach((bet) => {
          const existingIndexInDataRateInfoList = dataRateInfoList.findIndex((item) =>
            [item.betid1, item.betid2, item.betid3].includes(bet.betid)
          )
          if (existingIndexInDataRateInfoList !== -1) {
            const updateBetItem: BetItemPusher = {
              ...bet,
              rate: calculateBetRate({
                rate: bet.rate,
                percent_distribution: limitBetting?.BettingLimitsSportQuery.percent_distribution,
                rate_deduct: objExchangeRateInfo[updateRecordParentCalculateBetRate.sports_idx]
              })
            }
            dispatch(
              updateDataRateInfoList({
                betItemPusher: updateBetItem,
                fixture_idx: updateRecordParentCalculateBetRate.fixture_idx,
                index: existingIndexInDataRateInfoList
              })
            )
            // clearTimeouts()
            // timeoutRareInfo = setTimeout(() => {
            //   dispatch(updateAllStatusDefaultRateInfo())
            // }, 3000)
          }
        })
      }
      const updateRecordParentCalculateBetRate = { ...updateRecordParent }
      updateRecordParentCalculateBetRate.bets.forEach((bet) => {
        const existingCartItemIndex = cart.pick_list.findIndex((item) => item.betId === bet.betid)
        const updateBetItem: BetItemPusher = {
          ...bet,
          rate: calculateBetRate({
            rate: bet.rate,
            percent_distribution: limitBetting?.BettingLimitsSportQuery.percent_distribution,
            rate_deduct: objExchangeRateInfo[updateRecordParentCalculateBetRate.sports_idx]
          })
        }
        if (existingCartItemIndex !== -1) {
          if (
            bet.rate !== cart.pick_list[existingCartItemIndex].rate ||
            bet.status !== cart.pick_list[existingCartItemIndex].domesticData.status
          ) {
            // toast.warn(`선택된 경기중 변경된 사항이 있습니다.`)

            setIsOpenModalCartChange(true)
            dispatch(
              updateAndLockItem({
                domesticIdx: cart.pick_list[existingCartItemIndex].domesticData.idx,
                betId: updateBetItem.betid,
                isChanged:
                  updateBetItem.status !== 1 ||
                  !checkIsCanBettingRate({
                    game_id: cart.pick_list[existingCartItemIndex].domesticData.game_id,
                    sports_idx: cart.pick_list[existingCartItemIndex].domesticData.sports_idx,
                    rate: updateBetItem.rate
                  })
                    ? false
                    : true,
                newRate:
                  updateBetItem.status !== 1 ||
                  !checkIsCanBettingRate({
                    game_id: cart.pick_list[existingCartItemIndex].domesticData.game_id,
                    sports_idx: cart.pick_list[existingCartItemIndex].domesticData.sports_idx,
                    rate: updateBetItem.rate
                  })
                    ? cart.pick_list[existingCartItemIndex].rate
                    : updateBetItem.rate,
                status: updateBetItem.status,
                isLocked:
                  updateBetItem.status !== 1 ||
                  !checkIsCanBettingRate({
                    game_id: cart.pick_list[existingCartItemIndex].domesticData.game_id,
                    sports_idx: cart.pick_list[existingCartItemIndex].domesticData.sports_idx,
                    rate: updateBetItem.rate
                  })
              })
            )
          }
        }
      })
    })

    channel?.bind(SPORTS_FIXTURE_BLOCK_EVENT, async ({ data }: { data: { idx: number; block: boolean } }) => {
      if (!fixtureIdxList.includes(data.idx)) {
        return
      }
      dispatch(
        lockDataSearchPure({
          isLock: data.block,
          index: fixtureIdxList.indexOf(data.idx)
        })
      )
      const existingCartItemIndex = cart.pick_list.findIndex((item) => item.domesticData.fixture_idx === data.idx)
      if (existingCartItemIndex !== -1) {
        if (data.block) {
          setIsOpenModalCartChange(true)
          dispatch(
            updateAndLockItem({
              domesticIdx: cart.pick_list[existingCartItemIndex].domesticData.idx,
              betId: cart.pick_list[existingCartItemIndex].betId,
              isChanged: false,
              newRate: cart.pick_list[existingCartItemIndex].rate,
              status: cart.pick_list[existingCartItemIndex].domesticData.status,
              isLocked: true
            })
          )
        } else {
          setIsOpenModalCartChange(true)
          dispatch(
            updateAndLockItem({
              domesticIdx: cart.pick_list[existingCartItemIndex].domesticData.idx,
              betId: cart.pick_list[existingCartItemIndex].betId,
              isChanged: true,
              newRate: cart.pick_list[existingCartItemIndex].rate,
              status: cart.pick_list[existingCartItemIndex].domesticData.status,
              isLocked: false
            })
          )
        }
      }
    })

    return () => {
      channel?.unbind(SPORTS_UPDATE_EVENT)
      channel?.unbind(SPORTS_FIXTURE_BLOCK_EVENT)
      pusher?.unsubscribe(SPORTS_CHANNEL)
      // clearTimeout(timeoutData)
      // clearTimeout(timeoutRareInfo)
    }
  }, [dataSportSearchPure, cart, isOpenBoardBetSport, pusher])

  useEffect(() => {
    const interval = setInterval(() => {
      if (!config.demoMode) {
        dispatch(updateAllStatusDefault())
        dispatch(updateAllStatusDefaultRateInfo())
      }
    }, 12000)

    return () => clearInterval(interval)
  }, [])

  const handleCloseModalCartChange = () => {
    setIsOpenModalCartChange(false)
  }

  return {
    isOpenModalCartChange,
    handleCloseModalCartChange
  }
}
