import { AxiosRequestConfig } from 'axios'
import { sportsService } from 'src/libs/utils/axios.sports'
import { objToQueryParams } from 'src/libs/utils/utilFuncs'
import { CustomAxiosResponse } from 'src/types/axios.type'
import { ResponseGetAllLiveSports } from 'src/types/live-sports.type'
import {
  ResponseGetAllSports,
  ResponseGetMarketInfoList,
  ResponseGetRateInfoList,
  ResponseGetSportCategories
} from 'src/types/sports.type'
import { ResponseAllVirtualSports, VirtualSportsGroupByCates } from 'src/types/virtual-sport'
type GetAllSportsReq = {
  limit: number
  page: number
  sportsCode: string
  nationCode: string
  leagueCode: string
}

type GetRateInfoReq = {
  sportsCode: number
  leagueCode: number
  fixtureCode: number
}
export const sportsApi = {
  getAllSports({
    params,
    options
  }: {
    params: GetAllSportsReq
    options?: AxiosRequestConfig
  }): Promise<CustomAxiosResponse<ResponseGetAllSports>> {
    return sportsService.get(
      `/sports/prematch`,
      {
        ...params
      },
      {
        ...options
      }
    )
  },

  getAllLiveSports({
    params,
    options
  }: {
    params: GetAllSportsReq
    options?: AxiosRequestConfig
  }): Promise<CustomAxiosResponse<ResponseGetAllLiveSports>> {
    return sportsService.get(
      `/sports/inplay`,
      {
        ...params
      },
      {
        ...options
      }
    )
  },

  getSportCategories(): Promise<CustomAxiosResponse<ResponseGetSportCategories>> {
    return sportsService.get(`/sports/menu?type=cross`)
  },
  getLiveSportCategories(): Promise<CustomAxiosResponse<ResponseGetSportCategories>> {
    return sportsService.get(`/sports/menu?type=realtime`)
  },
  getSportRateInfoList({
    params,
    options
  }: {
    params: GetRateInfoReq
    options?: AxiosRequestConfig
  }): Promise<CustomAxiosResponse<ResponseGetRateInfoList>> {
    return sportsService.get(
      `/sports/markets`,
      {
        ...params,
        type: 'cross'
      },
      {
        ...options
      }
    )
  },
  getSportRateInfoLiveList({
    params,
    options
  }: {
    params: GetRateInfoReq
    options?: AxiosRequestConfig
  }): Promise<CustomAxiosResponse<ResponseGetRateInfoList>> {
    return sportsService.get(
      `/sports/markets`,
      {
        ...params,
        type: 'realtime'
      },
      {
        ...options
      }
    )
  },

  getMarketInfoList({
    options = {}
  }: {
    options?: AxiosRequestConfig
  }): Promise<CustomAxiosResponse<ResponseGetMarketInfoList>> {
    return sportsService.get(
      `/sports/market-list`,
      {},
      {
        ...options
      }
    )
  }
}
