import { gql } from '@apollo/client'

export const GET_PARSING_CASINO_MENU = gql`
  query MenuParsingCasinoQuery {
    MenuParsingCasinoQuery {
      pcpName
      pcpNameEn
      pcpLogo
      Providers {
        pcNo
        pcName
        pcNameEn
        pcPath
        pcFrameLink
        pcFrameWidth
        pcFrameHeight
      }
    }
  }
`

export const GET_PARSING_CASINO_CATEGGORY = gql`
  query CategoryParsingCasinoQuery {
    CategoryParsingCasinoQuery {
      gcReference
      pcName
    }
  }
`

export const GET_MINI_GAME_MGM_BACARAT = gql`
  query MGMBaccaratQuery($game: String) {
    MGMBaccaratQuery(game: $game) {
      round_id
      now
      blocking_time
      data {
        key
        text
        rate
        color
        category_id
        category_name
      }
    }
  }
`

export const GET_MINI_GAME_MGM_ODD_EVEN = gql`
  query MGMEvenOddQuery($game: String) {
    MGMEvenOddQuery(game: $game) {
      round_id
      now
      blocking_time
      data {
        key
        text
        rate
        color
        category_id
        category_name
      }
    }
  }
`

export const GET_MINI_GAME_MGM_GO_STOP = gql`
  query MGMGoStopQuery($game: String) {
    MGMGoStopQuery(game: $game) {
      round_id
      now
      blocking_time
      data {
        key
        text
        rate
        color
        category_id
        category_name
      }
    }
  }
`
export const GET_MINI_GAME_LOTUS_BACARAT = gql`
  query LotusBaccaratQuery($game: String) {
    LotusBaccaratQuery(game: $game) {
      round_id
      now
      blocking_time
      data {
        key
        text
        rate
        color
        category_id
        category_name
      }
    }
  }
`

export const GET_MINI_GAME_LOTUS_EVEN_ODD = gql`
  query LotusEvenOddQuery($game: String) {
    LotusEvenOddQuery(game: $game) {
      round_id
      now
      blocking_time
      data {
        key
        text
        rate
        color
        category_id
        category_name
      }
    }
  }
`

export const GET_PARSING_CASINO_GAME_BET_HISTORY = gql`
  query ParsingCasinoBettingList($page: Int, $limit: Int, $category: String) {
    ParsingCasinoBettingList(page: $page, limit: $limit, category: $category) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      data {
        tpcTransID
        tpcSelectedItem
        tpcBetAmount
        tpcWinAmount
        tpcRate
        tpcStatus
        tpcRegDate
        tpcDetails
        tpcResultBetting
        tpcProviderGame
      }
    }
  }
`
