import { gql } from '@apollo/client'

export const BET_MINI_GAME_MUTATION = gql`
  mutation BetMinigameMutation(
    $selectedItem: String
    $betAmount: Float
    $rate: Float
    $transId: String
    $details: String
    $game: String
  ) {
    BetMinigameMutation(
      selectedItem: $selectedItem
      betAmount: $betAmount
      rate: $rate
      transID: $transId
      details: $details
      game: $game
    )
  }
`
