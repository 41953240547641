/* eslint-disable @typescript-eslint/no-explicit-any */
export interface ResponseType {
  data?: DataTodoListType
  success?: boolean
}

export interface DataTodoListType {
  cntInfo?: CntInfo[]
  detailInfo?: DetailInfoType[]
  showGameCnt?: string
  curTime?: number
  pageStart?: number
  pageLength?: string
  threeFBonus?: string
  fourFBonus?: string
  fiveFBonus?: string
  sixFBonus?: string
  sevenFBonus?: string
  nineFBonus?: string
  betDelay?: number
  mapAllowMarketLimitIds?: MapAllowMarketLimitIds
  mapStrAllowMarketLimitIds?: MapAllowMarketLimitIds
  mapNotAllowMarketLimitIds?: any
  mapMarketLimitUseYn?: MapAllowMarketLimitIds
}

export interface CntInfo {
  sportsCode?: string
  sportsName?: string
  sportsNameEn?: string
  nationCntList?: NationCntList[]
  count?: number
}

export interface NationCntList {
  nationCode?: string
  nationName?: string
  imgPath?: string
  orderNum?: number
  leagueCntList?: LeagueCntList[]
  count?: number
}

export interface LeagueCntList {
  leagueCode?: string
  leagueName?: string
  imgPath?: string
  family?: string
  no?: number
  count?: string
}

export interface DetailInfoType {
  fixtureId?: number
  sportsCode?: string
  sportsName?: string
  sportsImgPath?: string
  nationName?: string
  leagueCode?: string
  leagueName?: string
  leagueImgPath?: string
  homeTeamName?: string
  awayTeamName?: string
  homeTeamImgPath?: string
  awayTeamImgPath?: string
  startDate?: string
  startDateMod?: string
  gameStatus?: string
  period?: string
  playTime?: string
  homeScore?: number
  awayScore?: number
  homeScores?: string
  awayScores?: string
  homeScoreSub?: number
  awayScoreSub?: number
  group?: number
  esportsType?: number
  rateInfo?: RateInfoType[]
  origRateInfo?: null
  groupCnt?: number
}

export interface RateInfoType {
  fixtureId?: number
  marketId?: number
  marketName?: string
  rateHomeStatus?: string
  rateAwayStatus?: string
  rateDrawStatus?: string
  family?: string | number
  providerName?: string
  lineScore?: string
  hbetCode?: string
  hpickName?: string
  hrate?: number
  hline?: string | number
  hbase?: number
  abetCode?: string
  apickName?: string
  arate?: number
  aline?: string
  abase?: number
  dbetCode?: string
  dpickName?: string
  drate?: number
  dline?: string
  dbase?: number
  bline?: string
}

export interface MapAllowMarketLimitIds {
  [key: string]: string[] | string[][]
}

export interface BetCartType {
  fixture_id?: number
  parent_idx?: number
  bet_code?: string
  select_rate?: string | number
  select_pick_desc?: string
  pick_detail?: string
  market_name?: string
  pick_title?: string
  key?: string
  status?: number
  marketId?: number
  select_idx?: number
  sports_code?: string
  sports_name?: string
  old_rate?: string
}

export interface BetDetailType {
  __typename?: string
  betId?: number
  status?: number
  fixtureId?: number
  selectRate?: number
  baseLine?: string
  startDate?: string
  selectIdx?: string
  selPickName?: string
  homeTeamName?: string
  awayTeamName?: string
  marketName?: string
  gameType?: string
}

export interface BettingSlipType {
  mem_idx?: number
  game_no?: string
  reg_date: string
  result?: number
  betting_cnt?: number
  confirmBetting?: number
  cashBet?: number
  rateBet?: string
  rateBonus?: number
  status?: number
  detailInfos: BetDetailType[]
}

export interface ListCartType {
  __typename?: string
  id?: number
  betDate?: string
  status?: number
  cashBet?: number
  rateBet?: string
  rateBonus?: number
}

export interface ListBetType {
  __typename?: string
  length?: number
  total?: number
  list?: ListCartType[]
  detailList?: BetDetailType[]
  bettingSlips: BettingSlipType[]
}

export interface ListBetHistoryQueryType {
  data?: [ListBetBattleQueryType]
  total?: number
  __typename?: string
  per_page: number
  current_page: number
  to: number
  last_page: number
  has_more_pages: boolean
  from: number
}
export interface ListBetBattleQueryType {
  __typename?: string
  betAmount?: number
  betList?: [ListBetBattleHistoryQueryType]
  date?: string
  estimateAmount?: number
  isCancelable?: boolean
  rate?: number
  settlementStatus?: number
  winAmount?: number
  now?: number
  timeLeftCancelable?: number
  timeLeftDisable?: number
  _id: string
  bonusFolder: number
  bonusFolderRate: number
  enableAutoCancel?: boolean
}

export interface ListBetBattleHistoryQueryType {
  __typename?: string
  status?: number
  selectRate?: number
  startDate?: string
  homeTeamName?: string
  awayTeamName?: string
  marketName?: string
  homeScore?: number
  awayScore?: number
  leagueName?: string
  teamAndRuleBet?: string
  selectedLine?: number
  labelBetStatus?: string
  fixtureStatus?: number
}

export interface ResListBetType {
  BetQuery?: ListBetType
}

export interface SportsBattleType {
  BetHistoryQuery?: ListBetHistoryQueryType
}

export type ResponseDataSport = {
  current_page: number
  data: SportItem[]
  first_page_url: string
  from: number
  last_page: number
  per_page: number
  to: number
  total: number
}

export interface SportItem {
  idx: number
  home_team: string
  home_team_kor?: string
  away_team: string
  away_team_kor?: string
  home_score: number
  away_score: number
  home_scores: any
  away_scores: any
  home_idx: number
  away_idx: number
  sports_idx: number
  league_idx: number
  location_idx: number
  location_name: string
  location_name_kor?: string
  start_date: string
  status: number
  period: number
  count: number
  league_name: string
  league_name_kor?: string
  league: League
  sports_name: string
  domestic: Domestic[]
  logo_location: string
  logo_sports: string
  block_all: boolean
  parents: Domestic[]
}

export enum EUpdateDomesticType {
  UP,
  DOWN,
  DEFAULT
}

export interface UpdateTypeRate {
  rate1: EUpdateDomesticType
  rate2: EUpdateDomesticType
  rate3: EUpdateDomesticType
}

export interface DomesticWithKeyMatch extends Domestic {
  keyMatch: string
  // for logic chosen from board ...
  isChosenFromBoard?: boolean
  isExistingInListDomesticOutSide?: boolean
  // for logic update
  updateType?: UpdateTypeRate
  leagueName: string

  isWinTieLose?: boolean

  // for pusher
  isShowInBoard?: boolean
}

export interface SportItemClient extends SportItem {
  domestic: DomesticWithKeyMatch[]
}

export interface Market {
  idx: number
  name: string
  name_en?: string
  group: number
}

export interface League {
  name: string
  idx: number
}

export interface Domestic {
  idx: number
  game_id: string
  sports_name: string
  fixture_idx: number
  sports_idx: number
  league_idx: number
  game_type: number
  game_kind: number
  game_time: string
  play_time: string
  home_team: string
  away_team: string
  location_name: string
  home_team_sub: string
  vs_team_sub: string
  away_team_sub: string
  rate1: number
  rate2: number
  rate3: number
  score1: string
  score2: string
  score3: string
  score4: string
  result: string
  isStop: string
  state: string
  money1: number
  money2: number
  money3: number
  auto_rate: number
  auto_result: number
  add_rate1: string
  add_rate2: string
  add_rate3: string
  betid1: string
  betid2: string
  betid3: string
  isMain: number
  main_line: string
  status: number
  market: Market
  status1: number
  status2: number
  status3: number
}

export interface CntInfoSport {
  sportsCode: number
  sportsName: string
  sportsNameEn: string
  count: number
  imgPath: string
  nationList: NationList[]
}

export interface NationList {
  nationCode: number
  nationName: string
  imgPath: string
  orderNum: number
  count: number
  leagueList: LeagueItem[]
}

export interface LeagueItem {
  leagueCode: number
  leagueName: string
  family: number
  count: number
}

export interface ResponseAllSportDetailInfo {
  detailInfo: ResponseDataSport
  cntInfo: CntInfoSport[]
}

export type ResponseGetAllSports = {
  data: ResponseAllSportDetailInfo

  success: boolean
}

export type CategorySportItem = {
  sportsCode: number
  sportsName: string
  sportsNameEn: string
  count: number
}

export type ResponseGetSportCategories = {
  data: CntInfoSport[]
  success: boolean
}

export type GroupSportListByKey = {
  key: string
  listGroupSport: SportItemClient[]
}

export type ResponseGetRateInfoList = {
  data: Domestic[]
  count: number
  success: boolean
}

export interface FolderBonusItem {
  bonus_folder_text?: string
  bonus_folder_key?: number
  bonus_folder_rate?: number
  bet_list_length?: number
}

export interface FolderBonusQueryRes {
  folders_bonus: FolderBonusItem[]
  text_folders_bonus: string
  min_rate: number
  is_use_bonus_folders: boolean
}

export interface FolderBonusQuery {
  FolderBonusQuery: FolderBonusQueryRes
}

export interface RuleItem {
  game_id: number
  list_allow: number[]
}

export interface ValidSelectOptionRuleItem {
  sports_code: number
  list_limit: RuleItem[]
  is_can_bet_draw: boolean
}

export interface ValidSelectOptionQuery {
  ValidSelectOptionQuery: ValidSelectOptionRuleItem[]
}

export interface ExchangeRateInfoItem {
  sports_code: number
  rate_deduct: number
}

export interface ExchangeRateInfoQuery {
  ExchangeRateInfoQuery: ExchangeRateInfoItem[]
}

export type LimitSport = {
  BettingLimitsSportQuery: {
    max_bet_amount: number
    max_bet_payout: number
    max_payout: number
    min_bet_amount: number
    percent_distribution: number
  }
}

export interface BetItemPusher {
  betid: string
  rate: number
  status: number
}

export interface UpdateRecordParent {
  type: string
  status: number
  sports_idx: number
  fixture_idx: number
  bets: BetItemPusher[]
}

export interface BetItemHistory {
  status: number
  selectRate: number
  startDate: string
  homeTeamName: string
  awayTeamName: string
  homeScore: number
  awayScore: number
  selectedLine: number
  leagueName: string
  betDetail: string
  betResult: string
  teamAndRuleBet: string
  marketName: string
  selectBetTeam: string
  labelBetStatus: string
}

export enum EStatusBetting {
  WAIT,
  LOSE,
  WIN,
  CANCEL,
  SPECIAL = 5
}

export interface BetHistoryGroup {
  _id: string
  betAmount: number
  date: string
  rate: number
  estimateAmount: number
  winAmount: number
  isCancelable: boolean
  settlementStatus: EStatusBetting
  now: number
  betList: BetItemHistory[]
}

export interface BetHistoryData {
  total: number
  per_page: number
  current_page: number
  from: number
  to: number
  last_page: number
  has_more_pages: boolean
  data: BetHistoryGroup[]
  __typename: string
}

export interface BetHistoryQuery {
  BetHistoryQuery: BetHistoryData
}

export const LIST_WIN_TIE_LOSE_ID = ['524', '348', '1', '41', '42', '43', '248', '282', '1561', '235']
export const LIST_WIN_TIE_LOSE_LINE_ID = ['13', '61']
export const LIST_WIN_LOSE_ID = [
  '226',
  '16',
  '63',
  '202',
  '2257',
  '2258',
  '1050',
  '2256',
  '52',
  '12',
  '2089',
  '1618',
  '1677'
]
export const LIST_FIRST_SCORE_ID = ['16']

export const LIST_WIN_LOSE_ID_ALL = [...LIST_WIN_LOSE_ID, ...LIST_FIRST_SCORE_ID]

export const LIST_UNDER_OVER_HOME_AWAY_OT_ID = ['221', '220']
export const LIST_UNDER_OVER_ID = [
  '525',
  '1538',
  '1537',
  '352',
  '835',
  '102',
  '2',
  '101',
  '21',
  '155',
  '153',
  '45',
  '156',
  '1852',
  '154',
  '2',
  '46',
  '222',
  '1052',
  '1051',
  '469',
  '47',
  '288',
  '287',
  '77',
  '355',
  '354',
  '1857',
  '28',
  '1858',
  '1120',
  '1562',
  '236',
  '2645',
  '2644',
  '166'
]
export const LIST_UNDER_OVER_ID_ALL = [...LIST_UNDER_OVER_HOME_AWAY_OT_ID, ...LIST_UNDER_OVER_ID]
export const LIST_HANDICAP_ID = [
  '329',
  '752',
  '337',
  '1558',
  '866',
  '526',
  '342',
  '64',
  '65',
  '3',
  '66',
  '283',
  '67',
  '53',
  '2057',
  '281',
  '201'
]
export const LIST_OU_HALFTIME_PERIOD_ID = [
  '21',
  '45',
  '46',
  '47',
  '153',
  '154',
  '223',
  '287',
  '155',
  '156',
  '222',
  '288',
  '77',
  '337',
  '354',
  '1051',
  '355',
  '1052',
  '836',
  '1054'
]

export const LIST_HANDICAP_HALFTIME_PERIOD_ID = ['64', '65', '66', '67', '53', '283']

export const LIST_ODD_EVEN_ID = [
  '51',
  '5',
  '199',
  '198',
  '72',
  '242',
  '243',
  '73',
  '292',
  '289',
  '74',
  '290',
  '75',
  '291',
  '387',
  '388',
  '62',
  '285',
  '2202'
]

export const LIST_SCORE_ID = ['6', '9', '100', '217']
export const LIST_YES_NO_ID = [
  '509',
  '69',
  '1868',
  '1867',
  '123',
  '104',
  '107',
  '96',
  '17',
  '113',
  '211',
  '1850',
  '105',
  '124',
  '97',
  '106',
  '219',
  '1935',
  '192',
  '727',
  '728',
  '729',
  '22',
  '23',
  '1864',
  '1863',
  '1676'
]

export const LIST_NO_GOAL_ID = ['59', '56', '92', '55']
export const LIST_NEITHER_ID = ['563']

export const LIST_ID_CHECK_RATE = [
  '101',
  '102',
  '153',
  '154',
  '155',
  '156',
  '401',
  '402',
  '558',
  '559',
  '1229',
  '1230',
  '1236',
  '1237',
  '1271',
  '1272',
  '525',
  '1538',
  '1537',
  '220',
  '352',
  '835',
  '2',
  '21',
  '45',
  '1852',
  '46',
  '222',
  '221',
  '1052',
  '1051',
  '469',
  '47',
  '288',
  '287',
  '77',
  '355',
  '354',
  '1857',
  '28',
  '1858',
  '1120',
  '1562',
  '236',
  '2645',
  '2644',
  '329',
  '752',
  '337',
  '1558',
  '866',
  '526',
  '342',
  '64',
  '65',
  '3',
  '66',
  '283',
  '67',
  '53',
  '2057',
  '281',
  '201'
]

export const SPORTS_IDX_BASEBALL = 35709

export const MAPPING_CATEGORY_BY_SPORTS_CODE: {
  [key: number]: {
    [key: number]: string
  }
} = {
  6046: {
    1: '메인마켓',
    2: '전반전/후반전',
    0: '추가배팅옵션'
  },
  48242: {
    1: '메인마켓',
    2: '쿼터별',
    0: '추가배팅옵션'
  },
  154914: {
    1: '메인마켓',
    2: '이닝별',
    0: '추가배팅옵션'
  },
  154830: {
    1: '메인마켓',
    2: '세트별',
    0: '추가배팅옵션'
  },
  35232: {
    1: '메인마켓',
    2: '피리어드별',
    0: '추가배팅옵션'
  },
  35709: {
    1: '메인마켓',
    2: '피리어드별',
    0: '전반전'
  },
  131506: {
    1: '메인마켓',
    2: '피리어드별',
    0: '추가배팅옵션'
  },
  54094: {
    1: '메인마켓',
    2: '세트별',
    0: '추가배팅옵션'
  },
  687890: {
    1: '메인마켓',
    2: '세트별',
    0: '추가배팅옵션'
  }
}

export type BoardBetSportsGroup = {
  key: number // market group
  group: number
  name: string
  // order: number
  sportsCode: number
  data: DomesticWithKeyMatch[]
}

export type BoardBetSportsItemGroup = {
  order: number
  marketName: string
  gameId: string
  sportsCode: number
  list: DomesticWithKeyMatch[]
}

export type MarketTransferItem = {
  sports_idx: number
  market_name: string
  group: 0
}

export type MarketInfoItem = {
  idx: number
  market_transfers: MarketTransferItem[]
  group: number
  name: string
}

export type ResponseGetMarketInfoList = {
  data: MarketInfoItem[]
}

export const rateKeyList: ['rate1', 'rate2', 'rate3'] = ['rate1', 'rate2', 'rate3']
export const statusKeyList: ['status1', 'status2', 'status3'] = ['status1', 'status2', 'status3']

export enum ESportsCode {
  Football = 6046,
  Basketball = 48242,
  Baseball = 154914,
  Volleyball = 154830,
  IceHockey = 35232,
  Handball = 35709,
  American_Football = 131506,
  Tennis = 54094,
  E_Game = 687890,
  Boxing = 154919
}

export const SPORT_CODE_SHOW_WINLOSE = [
  ESportsCode.Volleyball,
  ESportsCode.American_Football,
  ESportsCode.Tennis,
  ESportsCode.Baseball,
  ESportsCode.E_Game
]
export const SPORT_CODE_SWAP_WINLOSE_WINTIELOSE = [ESportsCode.Basketball]

export type ConditionRate = {
  min: number
  max: number
}

export const CONDITION_RATE_BASKETBALL: ConditionRate = {
  min: 1.6,
  max: 2.1
}
export const CONDITION_SHOW_IN_BOARD: {
  [key in ESportsCode]: {
    isNeedCheck: boolean
    condition: ConditionRate
  }
} = {
  [ESportsCode.Basketball]: {
    isNeedCheck: true,
    condition: CONDITION_RATE_BASKETBALL
  },
  [ESportsCode.Football]: {
    isNeedCheck: false,
    condition: { min: -999, max: 999 }
  },
  [ESportsCode.Baseball]: {
    isNeedCheck: false,
    condition: { min: -999, max: 999 }
  },
  [ESportsCode.Volleyball]: {
    isNeedCheck: false,
    condition: { min: -999, max: 999 }
  },
  [ESportsCode.IceHockey]: {
    isNeedCheck: false,
    condition: { min: -999, max: 999 }
  },
  [ESportsCode.Handball]: {
    isNeedCheck: false,
    condition: { min: -999, max: 999 }
  },
  [ESportsCode.American_Football]: {
    isNeedCheck: false,
    condition: { min: -999, max: 999 }
  },
  [ESportsCode.Tennis]: {
    isNeedCheck: false,
    condition: { min: -999, max: 999 }
  },
  [ESportsCode.E_Game]: {
    isNeedCheck: false,
    condition: { min: -999, max: 999 }
  },
  [ESportsCode.Boxing]: {
    isNeedCheck: false,
    condition: { min: -999, max: 999 }
  }
}

export const ORDER_MARKET_BY_GAME_ID_OBJ: {
  [key: number | ESportsCode]: {
    [key: number]: string[]
  }
} = {
  [ESportsCode.Basketball]: {
    1: ['1', '52', '226', '201', '3', '342', '2', '166', '28', '101', '102', '221', '220', '6', '5'],
    2: [
      '41',
      '42',
      '43',
      '44',
      '282',
      '284',
      '202',
      '203',
      '204',
      '205',
      '63',
      '286',
      '64',
      '65',
      '66',
      '67',
      '53',
      '283',
      '21',
      '45',
      '46',
      '47',
      '153',
      '154',
      '223',
      '287',
      '155',
      '156',
      '222',
      '288',
      '77',
      '337',
      '354',
      '1051',
      '355',
      '1052',
      '72',
      '73',
      '74',
      '75',
      '242',
      '289',
      '290',
      '291',
      '243',
      '292',
      '293',
      '294',
      '62',
      '529'
    ],
    0: ['2257', '1050', '2258', '2256', '105', '104', '69']
  },
  [ESportsCode.Football]: {
    1: ['1', '52', '226', '201', '3', '342', '2', '166', '28', '101', '102', '221', '220', '6', '5'],
    2: [
      '41',
      '42',
      '202',
      '203',
      '64',
      '65',
      '21',
      '836',
      '45',
      '1053',
      '153',
      '154',
      '155',
      '156',
      '9',
      '100',
      '72',
      '73',
      '41',
      '42',
      '43',
      '64',
      '65',
      '66',
      '21',
      '836',
      '45',
      '1053',
      '46',
      '1054',
      '153',
      '154',
      '223',
      '155',
      '156',
      '222',
      '9',
      '100',
      '217',
      '72',
      '73',
      '74'
    ],
    0: ['16', '55', '92', '6', '211', '212']
  },
  [ESportsCode.IceHockey]: {
    1: ['1', '52', '226', '201', '3', '342', '2', '166', '28', '101', '102', '221', '220', '6', '5'],
    2: [
      '41',
      '42',
      '43',
      '64',
      '65',
      '66',
      '21',
      '836',
      '45',
      '1053',
      '46',
      '1054',
      '153',
      '154',
      '223',
      '155',
      '156',
      '222',
      '9',
      '100',
      '217',
      '72',
      '73',
      '74',
      '727',
      '728',
      '729'
    ]
  },
  [ESportsCode.Volleyball]: {
    1: ['1', '52', '226', '201', '3', '342', '2', '166', '28', '101', '102', '221', '220', '6', '5'],
    2: [
      '202',
      '203',
      '204',
      '205',
      '206',
      '64',
      '65',
      '66',
      '67',
      '68',
      '21',
      '45',
      '46',
      '47',
      '48',
      '153',
      '154',
      '223',
      '287',
      '155',
      '156',
      '222',
      '288',
      '9',
      '100',
      '217',
      '394',
      '395',
      '72',
      '73',
      '74',
      '75',
      '76'
    ]
  },
  [ESportsCode.American_Football]: {
    1: ['1', '52', '226', '201', '3', '342', '2', '166', '28', '101', '102', '221', '220', '6', '5'],
    2: [
      '41',
      '42',
      '43',
      '44',
      '282',
      '284',
      '202',
      '203',
      '204',
      '205',
      '63',
      '464',
      '64',
      '65',
      '66',
      '67',
      '53',
      '283',
      '468',
      '21',
      '45',
      '46',
      '47',
      '153',
      '154',
      '223',
      '287',
      '155',
      '156',
      '222',
      '288',
      '77',
      '337',
      '354',
      '1051',
      '355',
      '1052',
      '72',
      '73',
      '74',
      '75',
      '242',
      '289',
      '290',
      '291',
      '243',
      '292',
      '293',
      '294',
      '62',
      '529'
    ]
  },
  [ESportsCode.Tennis]: {
    1: ['1', '52', '226', '201', '3', '342', '2', '166', '28', '101', '102', '221', '220', '6', '5'],
    2: [
      '202',
      '203',
      '204',
      '205',
      '206',
      '64',
      '65',
      '66',
      '67',
      '68',
      '21',
      '45',
      '46',
      '47',
      '48',
      '153',
      '154',
      '223',
      '287',
      '155',
      '156',
      '222',
      '288',
      '72',
      '73',
      '74',
      '75',
      '76'
    ]
  },
  [ESportsCode.E_Game]: {
    1: ['1', '52', '226', '201', '3', '342', '2', '166', '28', '101', '102', '221', '220', '6', '5'],
    2: [
      '202',
      '203',
      '204',
      '205',
      '206',
      '445',
      '446',
      '64',
      '65',
      '66',
      '67',
      '68',
      '21',
      '45',
      '46',
      '47',
      '48',
      '153',
      '154',
      '223',
      '287',
      '155',
      '156',
      '222',
      '288',
      '72',
      '73',
      '74',
      '75',
      '76'
    ]
  },
  [ESportsCode.Baseball]: {
    1: ['1', '52', '226', '201', '3', '342', '2', '166', '28', '101', '102', '221', '220', '6', '5'],
    2: [
      '202',
      '203',
      '204',
      '205',
      '206',
      '1618',
      '63',
      '286',
      '41',
      '42',
      '43',
      '44',
      '49',
      '235',
      '282',
      '284',
      '64',
      '65',
      '281',
      '526',
      '53',
      '283',
      '21',
      '45',
      '46',
      '47',
      '1427',
      '1562',
      '236',
      '525',
      '2644',
      '2645',
      '72',
      '73',
      '74',
      '75',
      '242',
      '289',
      '290',
      '291',
      '243',
      '292',
      '293',
      '294',
      '62',
      '529'
    ]
  }
}
